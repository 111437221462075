import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function PrivacyPage(props) {
  //const { intl } = props;

  return (
<div className="d-flex flex-column flex-root" style={{maxHeight:"100%"}}>
    {/*begin::Login*/}
    <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" style={{maxHeight:"100%"}}>
      {/*begin::Aside*/}
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/auth.jpg")})`,
        }}
      >
        {/*begin: Aside Container*/}
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          {/* start:: Aside header */}
          <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
            <img
              alt="Logo"
              className="max-h-70px"
              src={toAbsoluteUrl("/media/logos/logo-letter-invert.png")}
            />
          </Link>
          {/* end:: Aside header */}

          {/* start:: Aside content */}
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 className="font-size-h1 mb-5 text-white">
              Privacy Policy
            </h3>
          </div>
          {/* end:: Aside content */}

          {/* start:: Aside footer for desktop */}
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold	text-white">
              &copy; 2021 InkSeps
            </div>
            <div className="d-flex">
              <Link to="/privacy-policy" className="text-white">
                Privacy
              </Link>
              <Link to="/terms-and-conditions" className="text-white ml-10">
                Terms
              </Link>
              <a
                href="https://inkseps.com/contact"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white ml-10"
              >
                Contact
              </a>
            </div>
          </div>
          {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/}
      </div>
      {/*begin::Aside*/}

      {/*begin::Content*/}
      <div className="d-flex flex-fill p-7 overflow-auto">

        {/* begin::Content body */}
        <div className="mt-10 mt-lg-0">

            <p><b>Privacy Policy</b></p>
            <p>
                Protecting your private information is our priority. This Statement of Privacy applies to&#160;<br/>
                https://inkseps.com, and CWB Technologies LLC and governs data collection and usage. For the&#160;<br/>
                purposes of this Privacy Policy, unless otherwise noted, all references to CWB Technologies LLC&#160;<br/>
                include https://inkseps.com and CWB Technologies. The CWB Technologies website is a Photo&#160;<br/>
                Separator &amp; Editor site. By using the CWB Technologies website, you consent to the data&#160;<br/>
                practices described in this statement.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                <b>Collection of your Personal Information</b>&#160;&#160;<br/>
                In order to better provide you with products and services offered, CWB Technologies may collect&#160;<br/>
                personally identifiable information, such as your:
            </p>
            <p>- First and Last Name</p>
            <p>- E-mail Address</p>
            <p>
                We do not collect any personal information about you unless you voluntarily provide it to us.&#160;<br/>
                However, you may be required to provide certain personal information to us when you elect to use&#160;<br/>
                certain products or services. These may include: (a) registering for an account; (b) entering a&#160;<br/>
                sweepstakes or contest sponsored by us or one of our partners; (c) signing up for special offers&#160;<br/>
                from selected third parties; (d) sending us an email message; (e) submitting your credit card or&#160;<br/>
                other payment information when ordering and purchasing products and services. To wit, we will&#160;<br/>
                use your information for, but not limited to, communicating with you in relation to services and/or&#160;<br/>
                products you have requested from us. We also may gather additional personal or non-personal&#160;<br/>
                information in the future.&#160;&#160;<br/>
            </p>

            <p>
                <b>Use of your Personal Information&#160;</b>&#160;<br/>
                CWB Technologies collects and uses your personal information to operate and deliver the services&#160;<br/>
                you have requested.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                CWB Technologies may also use your personally identifiable information to inform you of other&#160;<br/>
                products or services available from CWB Technologies and its affiliates.&#160;&#160;<br/>
            </p>

            <p>
                <b>Sharing Information with Third Parties</b>&#160;&#160;<br/>
                CWB Technologies does not sell, rent or lease its customer lists to third parties.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                CWB Technologies may share data with trusted partners to help perform statistical analysis, send&#160;<br/>
                you email or postal mail, provide customer support, or arrange for deliveries. All such third parties&#160;<br/>
                are prohibited from using your personal information except to provide these services to CWB&#160;<br/>
                Technologies, and they are required to maintain the confidentiality of your information.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                CWB Technologies may disclose your personal information, without notice, if required to do so by&#160;<br/>
                law or in the good faith belief that such action is necessary to: (a) conform to the edicts of the law&#160;<br/>
                or comply with legal process served on CWB Technologies or the site; (b) protect and defend the&#160;<br/>
                rights or property of CWB Technologies; and/or (c) act under exigent circumstances to protect the&#160;<br/>
                personal safety of users of CWB Technologies, or the public.&#160;&#160;<br/>
            </p>
            <p>
                <b>Tracking User Behavior</b>&#160;&#160;<br/>
                CWB Technologies may keep track of the websites and pages our users visit within CWB&#160;<br/>
                Technologies, in order to determine what CWB Technologies services are the most popular. This&#160;<br/>
                data is used to deliver customized content and advertising within CWB Technologies to customers&#160;<br/>
                whose behavior indicates that they are interested in a particular subject area.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                <b>Automatically Collected Information</b>&#160;&#160;<br/>
                Information about your computer hardware and software may be automatically collected by CWB&#160;<br/>
                Technologies. This information can include: your IP address, browser type, domain names, access&#160;<br/>
                times and referring website addresses. This information is used for the operation of the service, to&#160;<br/>
                maintain quality of the service, and to provide general statistics regarding use of the CWB&#160;<br/>
                Technologies website.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                <b>Security of your Personal Information</b>&#160;&#160;<br/>
                CWB Technologies secures your personal information from unauthorized access, use, or&#160;<br/>
                disclosure. CWB Technologies uses the following methods for this purpose:
            </p>
            <p>- SSL Protocol</p>
            <p>- Encryption</p>
            <p>
                When personal information (such as a credit card number) is transmitted to other websites, it is&#160;<br/>
                protected through the use of encryption, such as the Secure Sockets Layer (SSL) protocol.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                We strive to take appropriate security measures to protect against unauthorized access to or&#160;<br/>
                alteration of your personal information. Unfortunately, no data transmission over the Internet or any&#160;<br/>
                wireless network can be guaranteed to be 100% secure. As a result, while we strive to protect&#160;<br/>
                your personal information, you acknowledge that: (a) there are security and privacy limitations&#160;<br/>
                inherent to the Internet which are beyond our control; and (b) security, integrity, and privacy of any&#160;<br/>
                and all information and data exchanged between you and us through this Site cannot be&#160;<br/>
                guaranteed.
            </p>

            <p>
                <b>Right to Deletion</b>&#160;&#160;<br/>
                Subject to certain exceptions set out below, on receipt of a verifiable request from you, we will:
            </p>
            <p>• Delete your personal information from our records; and</p>
            <p>• Direct any service providers to delete your personal information from their records.</p>
            <p>
                Please note that we may not be able to comply with requests to delete your personal information if&#160;<br/>
                it is necessary to:
            </p>
            <p>• Complete the transaction for which the personal information was collected, fulfill the&#160;<br/>
                terms of a written warranty or product recall conducted in accordance with federal&#160;<br/>
                law, provide a good or service requested by you, or reasonably anticipated within the&#160;<br/>
                context of our ongoing business relationship with you, or otherwise perform a contract&#160;<br/>
                between you and us;
            </p>
            <p>• Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal&#160;<br/>
                activity; or prosecute those responsible for that activity;
            </p>
            <p>• Debug to identify and repair errors that impair existing intended functionality;</p>
            <p>• Exercise free speech, ensure the right of another consumer to exercise his or her right&#160;<br/>
                of free speech, or exercise another right provided for by law;
            </p>
            <p>• Comply with the California Electronic Communications Privacy Act;</p>
            <p>• Engage in public or peer-reviewed scientific, historical, or statistical research in the&#160;<br/>
                public interest that adheres to all other applicable ethics and privacy laws, when our&#160;<br/>
                deletion of the information is likely to render impossible or seriously impair the&#160;<br/>
                achievement of such research, provided we have obtained your informed consent;
            </p>
            <p>• Enable solely internal uses that are reasonably aligned with your expectations based on&#160;<br/>
                your relationship with us;
            </p>
            <p>• Comply with an existing legal obligation; or</p>
            <p>• Otherwise use your personal information, internally, in a lawful manner that is&#160;<br/>
                compatible with the context in which you provided the information.
            </p>
            
            <p>
                <b>Children Under Thirteen</b>&#160;&#160;<br/>
                CWB Technologies does not knowingly collect personally identifiable information from children&#160;<br/>
                under the age of thirteen. If you are under the age of thirteen, you must ask your parent or guardian&#160;<br/>
                for permission to use this website.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                <b>E-mail Communications</b>&#160;&#160;<br/>
                From time to time, CWB Technologies may contact you via email for the purpose of providing&#160;<br/>
                announcements, promotional offers, alerts, confirmations, surveys, and/or other general&#160;<br/>
                communication.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                If you would like to stop receiving marketing or promotional communications via email from CWB&#160;<br/>
                Technologies, you may opt out of such communications by clicking the unsubscribe link.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
                <b>Changes to this Statement</b>&#160;&#160;<br/>
                CWB Technologies reserves the right to change this Privacy Policy from time to time. We will&#160;<br/>
                notify you about significant changes in the way we treat personal information by sending a notice to&#160;<br/>
                the primary email address specified in your account, by placing a prominent notice on our website,&#160;<br/>
                and/or by updating any privacy information. Your continued use of the website and/or Services&#160;<br/>
                available after such modifications will constitute your: (a) acknowledgment of the modified Privacy&#160;<br/>
                Policy; and (b) agreement to abide and be bound by that Policy.&#160;&#160;<br/>
                &#160;&#160;&#160;<br/>
            </p>

            <p>
                <b>Contact Information</b>&#160;&#160;<br/>
                CWB Technologies welcomes your questions or comments regarding this Statement of Privacy. If&#160;<br/>
                you believe that CWB Technologies has not adhered to this Statement, please contact CWB&#160;<br/>
                Technologies at:
            </p>
            
            <p>
                CWB Technologies LLC&#160;&#160;<br/>

                36676 Douglas Fir Court&#160;&#160;<br/>

                Singletown, California 96088
            </p>
            <p>
                Email Address:&#160;&#160;<br/>

                contact@inkseps.com
            </p>
            <p>
                Telephone number:&#160;&#160;<br/>

                18338535342
            </p>
            <p>
                Effective as of December 01, 2021
            </p>
        </div>
        {/*end::Content body*/}

        {/* begin::Mobile footer */}
        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
          <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
            &copy; 2021 InkSeps
          </div>
          <div className="d-flex order-1 order-sm-2 my-2">
            {/*<Link to="/terms" className="text-dark-75 text-hover-primary">
              Privacy
            </Link>
            <Link
              to="/terms"
              className="text-dark-75 text-hover-primary ml-4"
            >
              Legal
            </Link>*/}
            <a
              href="https://inkseps.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              Contact
            </a>
          </div>
        </div>
        {/* end::Mobile footer */}
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Login*/}
  </div>
  );
}
