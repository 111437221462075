import React, { useState } from "react";
import cookie from "react-cookies";
import { Layer } from "../../../../app/components/Layer/Layer";
import { LayerEditor } from "../../../../app/components/Layer/LayerEditor";
import { Grid, FormControlLabel, Checkbox, Popover, Switch, Slider } from "@material-ui/core";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
import { ComingSoon } from "../../../../app/components/ComingSoon";
import { LayerDialog } from "../../../../app/components/Layer/LayerDialog";
import { OverlayFilter } from "@flat-gl/core";
import { LightTooltip } from "../../../../app/components/LightTooltip";
import { ChromePicker } from "react-color";
import { FormattedMessage } from "react-intl";
import { MemberBadge } from "../../../../app/components/MemberBadge";

const locked = ["WhiteBase", "White", "Original"];

export function AsideMenuList({
  flatGl,
  refreshLayers,
  layers,
  viewMode,
  setViewMode,
  smartMerge,
  setSmartMerge,
  includeGray,
  setIncludeGray,
  currentLayer,
  setCurrentLayer,
  shirtColor,
  setShirtColor,
}) {
  if (!flatGl.isBrowserSupported) {

  }
  /*const location = useLocation();
  const getMenuItemActive = (layer) => {
    return flatGl.canvas.activeLayer === layer
      ? ` menu-item-active menu-item-open menu-item-not-hightlighted`
      : "";
  };*/
  const [twoTone, setTwoTone] = useState(false);

  const [hueRadio, _setHueRadio] = useState(parseInt(cookie.load("hueRadio")) || 6);
  flatGl.hues = hueRadio;
  const setHueRadio = (value) => {
    flatGl.hues = value;
    cookie.save("hueRadio", value, { path: "/" });
    _setHueRadio(value);
  };
  
  // dialog box
  const [openDialog, setOpenDialog] = useState(false);
  const openLayerDialog = (layer) => {
    setCurrentLayer(layer);
    setOpenDialog(true);
  };

  // channels view
  const getLayersList = (layers) => {
    const list = [];
    layers.forEach((layer, key) => {
      if (layer.name === "WhiteBase") {
        return;
      }

      list.push(
        <Layer key={layer.uuid} flatGl={flatGl} layer={layer} layers={layers} currentLayer={currentLayer} locked={locked.indexOf(layer.name) !== -1} getCompactLayerList={getCompactLayerList} setCurrentLayer={setCurrentLayer} openDialog={openDialog} openLayerDialog={openLayerDialog}>
        </Layer>
      );
    });

    return list;
  };

  // split view
  //const [colorList, setColorList] = useState([]);
  const getCompactLayerList = (onClick, exclude = [], ignoreCurrent) => {
    const list = [];
    layers.forEach((layer, key) => {
      if (exclude.indexOf(layer.name) !== -1) {
        return;
      }

      const overlayFilter = layer.getFilterByType(OverlayFilter)[0];
      const swatch = <Grid item
        key={layer.uuid}
        className={`color-chip large m-1 cursor-pointer ${currentLayer === layer || ignoreCurrent ? "" : "opacity-50"} ${layer.name.toLowerCase()}`}
        style={overlayFilter ? {backgroundColor:`rgb(${overlayFilter.rgba[0] * 255}, ${overlayFilter.rgba[1] * 255}, ${overlayFilter.rgba[2] * 255})`} : {}}
        onClick={() => onClick(layer)}
      >
        <LightTooltip title={layer.name} aria-label={layer.name} placement="bottom">
          <div className="w-100 h-100">
            {layer.name === "Original" && <i className="fa fa-2x fa-image text-dark" style={{marginTop:"-3px"}}></i>}
          </div>
        </LightTooltip>
      </Grid>;

      if (layer.name === "White") {
        list.splice(1, 0, swatch);
      } else {
        list.push(swatch);
      }
    });
    list.reverse();

    return list;
  }

  const [shirtColorPicker, setShirtColorPicker] = useState({r: 0, g: 0, b: 0, a: 1});
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  function handleShirtClick(event) {
    setAnchorEl(event.currentTarget);
  }
  function handleMergeClose() {
    setAnchorEl(null);
  }

  function handleToggleGray() {
    flatGl.includeGray = !includeGray;
    flatGl.updateGray();
    setIncludeGray(flatGl.includeGray);
  }

  const splitView = viewMode === "leftRight" || viewMode === "topBottom";

  return (<div>
  {!layers.length && <div className="px-3">
    <h2 className="text-light"><FormattedMessage id="DASHBOARD.STEP_1" /></h2>
    <p className="text-light pb-5"><FormattedMessage id="DASHBOARD.SELECT_OPTIONS" /></p>

    <div className="fade alert alert-warning show text-justify" role="alert">
      <FormattedMessage id="DASHBOARD.WELCOME_WARNING" />
    </div>

    <div className={`card card-custom wave mb-4 ${hueRadio === 6 ? "" : "opacity-50"}`}>
      <div className="card-body cursor-pointer p-3" onClick={() => setHueRadio(6)}>
        <div className="d-flex align-items-center p-1">
          <div className="mr-4">
            <span className="svg-icon svg-icon-success svg-icon-4x">
              <div className="radio-inline">
                <label className="radio radio-outline radio-success">
                  <input type="radio" value={6} checked={hueRadio === 6} readOnly={true} />
                  <span></span>
                </label>
              </div>
            </span>
          </div>
          <div className="d-flex flex-column flex-fill">
            <div className="text-dark font-weight-bold font-size-h4">
            <FormattedMessage id="DASHBOARD.6_COLORS" />
              <FormControlLabel
                style={{position:"absolute", right:0, top:"15px"}}
                label={<FormattedMessage id="DASHBOARD.GRAY" />}
                control={
                  <Checkbox checked={includeGray} onChange={(event) => setIncludeGray(event.target.checked)} value="includeGray" className="p-0 pr-1" />
                }
              />
            </div>
            <div className="text-dark-75 mt-3">
              <span className="color-chip mr-1 red"></span>
              <span className="color-chip mr-1 yellow"></span>
              <span className="color-chip mr-1 green"></span>
              <span className="color-chip mr-1 cyan"></span>
              <span className="color-chip mr-1 blue"></span>
              <span className="color-chip mr-1 magenta"></span>
              <span className="color-chip separator mr-1 mt-n1">+</span>
              <span className="color-chip mr-1 white"></span>
              {includeGray && <span className="color-chip mr-1 gray"></span>}
              <span className="color-chip black"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className={`card card-custom wave mb-4 ${hueRadio === 12 ? "" : "opacity-50"}`}>
      <MemberBadge placement="inside" />
      <div className="card-body cursor-pointer p-3" onClick={() => setHueRadio(12)}>
        <div className="d-flex align-items-center p-1">
          <div className="mr-4">
            {/*<i className="text-warning flaticon-star"></i>*/}
            <span className="svg-icon svg-icon-success svg-icon-4x">
              <div className="radio-inline">
                <label className="radio radio-outline radio-success">
                  <input type="radio" value={12} checked={hueRadio === 12} readOnly={true} />
                  <span></span>
                </label>
              </div>
            </span>
          </div>
          <div className="d-flex flex-column flex-fill">
            <div className="text-dark font-weight-bold font-size-h4">
              <FormattedMessage id="DASHBOARD.12_COLORS" />
              <FormControlLabel
                style={{position:"absolute", right:0, top:"15px"}}
                label={<FormattedMessage id="DASHBOARD.GRAY" />}
                control={
                  <Checkbox checked={includeGray} onChange={(event) => setIncludeGray(event.target.checked)} value="includeGray" className="p-0 pr-1" />
                }
              />
            </div>
            <div className="text-dark-75 mt-3">
              <span className="color-chip mr-1 red"></span>
              <span className="color-chip mr-1 orange"></span>
              <span className="color-chip mr-1 yellow"></span>
              <span className="color-chip mr-1 lime"></span>
              <span className="color-chip mr-1 green"></span>
              <span className="color-chip mr-1 mint"></span>
              <span className="color-chip mr-1 cyan"></span>
              <span className="color-chip mr-1 azure"></span>
              <span className="color-chip mr-1 blue"></span>
              <span className="color-chip mr-1 violet"></span>
              <span className="color-chip mr-1 magenta"></span>
              <span className="color-chip mr-1 rose"></span>
              <span className="color-chip separator mr-1 mt-n1">+</span>
              <span className="color-chip mr-1 white"></span>
              {includeGray && <span className="color-chip mr-1 gray"></span>}
              <span className="color-chip black"></span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ComingSoon>
      <div className={`card card-custom wave mb-4 ${hueRadio === 3 ? "" : "opacity-50"}`}>
        <MemberBadge placement="inside" />
        <div className="card-body cursor-pointer p-3" onClick={() => setHueRadio(3)}>
          <div className="d-flex align-items-center p-1">
            <div className="mr-4">
              <span className="svg-icon svg-icon-success svg-icon-4x">
                <div className="radio-inline">
                  <label className="radio radio-outline radio-success">
                    <input type="radio" value={3} checked={hueRadio === 3} readOnly={true} />
                    <span></span>
                  </label>
                </div>
              </span>
            </div>
            <div className="d-flex flex-column flex-fill">
              <div className="text-dark font-weight-bold font-size-h4">
                <FormattedMessage id="DASHBOARD.GRAYSCALE" />
                <FormControlLabel
                  style={{position:"absolute", right:0, top:"15px"}}
                  label={<FormattedMessage id="DASHBOARD.2_TONE" />}
                  control={
                    <Checkbox checked={twoTone} onChange={(event) => setTwoTone(event.target.checked)} value="twoTone" className="p-0 pr-1" />
                  }
                />
              </div>
              <div className="text-dark-75 mt-3">
                <span className="color-chip mr-1 white"></span>
                {!twoTone && <span className="color-chip mr-1 gray"></span>}
                {twoTone && <>
                  <span className="color-chip mr-1 gray-1"></span>
                  <span className="color-chip mr-1 gray-2"></span>
                  </>}
                <span className="color-chip black"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComingSoon>

    <ComingSoon>
      <div className={`card card-custom wave mb-8 ${hueRadio === 3 ? "" : "opacity-50"}`}>
        <MemberBadge placement="inside" />
        <div className="card-body cursor-pointer p-3" onClick={() => setHueRadio(4)}>
          <div className="d-flex align-items-center p-1">
            <div className="mr-4">
              <span className="svg-icon svg-icon-success svg-icon-4x">
                <div className="radio-inline">
                  <label className="radio radio-outline radio-success">
                    <input type="radio" value={4} checked={hueRadio === 4} readOnly={true} />
                    <span></span>
                  </label>
                </div>
              </span>
            </div>
            <div className="d-flex flex-column flex-fill">
              <div className="text-dark font-weight-bold font-size-h4">
                <FormattedMessage id="DASHBOARD.TRITONE" />
              </div>
              <div className="text-dark-75 mt-3">
                <span className="color-chip mr-1 azure"></span>
                <span className="color-chip separator mr-1 mt-n1">+</span>
                <span className="color-chip mr-1 white"></span>
                <span className="color-chip mr-1 gray"></span>
                <span className="color-chip black"></span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ComingSoon>

    <div className="card card-custom mb-4">
      <div className="card-body pt-1 pb-0 px-5 d-flex flex-column align-items-center">
        <LightTooltip title={<FormattedMessage id="DASHBOARD.SMART_MERGE_TIP" />} placement="right">
          <FormControlLabel
            control={<Switch color="primary" checked={smartMerge !== 0} onChange={() => setSmartMerge(smartMerge ? 0 : 2)} />}
            label={<FormattedMessage id="DASHBOARD.SMART_MERGE" />}
            className="text-dark"
          />
        </LightTooltip>
        {smartMerge > 0 && <>
          <small><FormattedMessage id="DASHBOARD.MERGE_THRESHOLD" /></small>
          <Slider
              min={0.5}
              max={15}
              step={0.5}
              valueLabelDisplay="auto"
              value={smartMerge}
              onChange={(event, value) => setSmartMerge(value)}
              className="pt-2"
          />
        </>}
      </div>
    </div>
  </div>}

  <ButtonGroup toggle className="w-100 px-3 mb-6">
    <ToggleButton type="radio" className={`px-2 btn-light text-dark ${(viewMode === "leftRight" || viewMode === "topBottom") ? "" : "opacity-50"}`} onClick={() => setViewMode(flatGl.canvas.width / flatGl.canvas.height > 1.75 ? "topBottom" : "leftRight")}>
      <MemberBadge placement="outside" />
      <i className="fa fa-columns"></i> <FormattedMessage id="DASHBOARD.SPLIT_VIEW" />
    </ToggleButton>
    <ToggleButton type="radio" className={`px-2 btn-light text-dark ${viewMode === "fullPreview" ? "" : "opacity-50"}`} onClick={() => setViewMode("fullPreview")}>
      <i className="fa fa-list"></i> <FormattedMessage id="DASHBOARD.CHANNELS_VIEW" />
    </ToggleButton>
  </ButtonGroup>
  
  {(!splitView && !!layers.length) && <>
    <Grid container direction="row" alignItems="center" alignContent="center" className="mb-2">
      <Grid xs item className="text-center">
        <LightTooltip title={includeGray ? <FormattedMessage id="EDITOR.EXCLUDE_GRAY" /> : <FormattedMessage id="EDITOR.INCLUDE_GRAY" />} aria-label={includeGray ? "Exclude Gray" : "Include Gray"} placement="bottom">
          <i className={`fa ${includeGray ? "fa-toggle-on" : "fa-toggle-off"} cursor-pointer`} aria-describedby={"toggle-gray-popover"} variant="contained" onClick={handleToggleGray}></i>
        </LightTooltip>
      </Grid>
      <Grid xs item className="text-center">
        <LightTooltip title={<FormattedMessage id="EDITOR.SHIRT_COLOR" />} aria-label="Shirt Color (Background)" placement="bottom">
          <i className="fa fa-tshirt cursor-pointer" aria-describedby={"shirt-color-popover"} variant="contained" onClick={handleShirtClick}></i>
        </LightTooltip>
      </Grid>
    </Grid>
    <ul className={`menu-nav pt-1`}>
      {getLayersList(layers)}
    </ul>
  </>}
  {(splitView && !!layers.length) && <div className="px-3">
    <Grid container spacing={1} alignItems="center" className="mb-2">
      <Grid item xs className="pb-0">
        <div className="card card-custom">
          <div className="card-body p-1">
            <Grid container style={{paddingLeft:"2px"}}>
              {getCompactLayerList((layer) => setCurrentLayer(layer), ["WhiteBase"])}
            </Grid>
          </div>
        </div>
      </Grid>
      <Grid item className="text-center">
        <LightTooltip title={includeGray ? <FormattedMessage id="EDITOR.EXCLUDE_GRAY" /> : <FormattedMessage id="EDITOR.INCLUDE_GRAY" />} aria-label={includeGray ? "Exclude Gray" : "Include Gray"} placement="bottom">
          <i className={`fa ${includeGray ? "fa-toggle-on" : "fa-toggle-off"} cursor-pointer`} aria-describedby={"toggle-gray-popover"} variant="contained" onClick={handleToggleGray}></i>
        </LightTooltip>
        <br />
        <LightTooltip title={<FormattedMessage id="EDITOR.SHIRT_COLOR" />} aria-label="Shirt Color (Background)" placement="bottom">
          <i className="fa fa-tshirt cursor-pointer mt-4" aria-describedby={"shirt-color-popover"} variant="contained" onClick={handleShirtClick}></i>
        </LightTooltip>
      </Grid>
    </Grid>
    {currentLayer && <LayerEditor lightMode={true} toolsOnly={false} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} getCompactLayerList={getCompactLayerList} layers={layers} refreshLayers={refreshLayers} />}
  </div>}
  <Popover
    id={"shirt-color-popover"}
    open={open}
    anchorEl={anchorEl}
    onClose={handleMergeClose}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
  >
    <div className="p-3">
      <ChromePicker color={shirtColorPicker} onChangeComplete={(value) => {
        setShirtColorPicker(value.rgb);
        setShirtColor([value.rgb.r, value.rgb.g, value.rgb.b, value.rgb.a]);
      }} />
    </div>
  </Popover>
            
  <LayerDialog flatGl={flatGl} layer={currentLayer} setCurrentLayer={setCurrentLayer} layers={layers} refreshLayers={refreshLayers} getCompactLayerList={getCompactLayerList} openDialog={openDialog} onClose={() => {
      // re-enable OverlayFilter
      currentLayer.filters.forEach((filter) => {
          if (filter instanceof OverlayFilter) {
              filter.enabled = true;
          }
      });
      setOpenDialog(false);
  }} />
</div>);
}
