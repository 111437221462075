import React, { useEffect, useState } from "react";
import { Grid, Slider } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { LevelsFilter } from "@flat-gl/core";
import { FormattedMessage } from "react-intl";

export function LayerLevels({lightMode, layer}) {
    const [levels, setLevels] = useState([0, 1.0, 1.0, 0.0, 1.0]);

    // shadows/highlights (minInput, maxInput)
    const [minMaxInput, setMinMaxInput] = useState([
        Math.round(levels[0] * 255),
        Math.round(levels[2] * 255)
    ]);
    const setLevelsMinMaxInput = (event, value) => {
        levels[0] = value[0] / 255;
        levels[2] = value[1] / 255;
        setLevels(levels);
        setMinMaxInput(value);
        
        // update filter
        layer.setFilter(LevelsFilter, [levels]);
    };

    // midtones (gamma)
    const [midtones, setMidtones] = useState(levels[1]);
    const setLevelsMidtones = (event, value) => {
        levels[1] = value;
        setLevels(levels);
        setMidtones(value);

        // update filter
        layer.setFilter(LevelsFilter, [levels]);
    };

    // shadows/highlights (minInput, maxInput)
    const [minMaxOutput, setMinMaxOutput] = useState([
        Math.round(levels[3] * 255),
        Math.round(levels[4] * 255)
    ]);
    const setLevelsMinMaxOutput = (event, value) => {
        levels[3] = value[0] / 255;
        levels[4] = value[1] / 255;
        setLevels(levels);
        setMinMaxOutput(value);
        
        // update filter
        layer.setFilter(LevelsFilter, [levels]);
    };
    
    useEffect(() => {
        if (!layer) {
            return;
        }

        const filter = layer.getFilterByType(LevelsFilter)[0];
        if (filter) {
            setLevels(filter.levels);
        } else {
            setLevels([0, 1.0, 1.0, 0.0, 1.0]);
        }
    }, [layer]);

    useEffect(() => {
        setMinMaxInput([
            Math.round(levels[0] * 255),
            Math.round(levels[2] * 255)
        ]);
        setMidtones(levels[1]);
        setMinMaxOutput([
            Math.round(levels[3] * 255),
            Math.round(levels[4] * 255)
        ]);
    }, [levels])

    const valueLabelFormat = (value) => Math.round((value - 1) * 100) / 100;

    // no layer
    if (!layer) {
        return <></>;
    }

    return (
<Grid container spacing={2} alignItems="center" className="mt-2 py-2 border-top border-themed">
    <Grid item>
        <LightTooltip title={<FormattedMessage id="EDITOR.SHADOWS" />} aria-label="Min/Max Input (Shadows/Highlights)" placement="right">
            <i className="fa fa-sliders-h"></i>
        </LightTooltip>
        <br />
        <LightTooltip title={<FormattedMessage id="EDITOR.MIDTONES" />} aria-label="Gamma (Midtones)" placement="right">
            <i className="my-5 fa fa-sliders-h"></i>
        </LightTooltip>
        <br />
        <LightTooltip title={<FormattedMessage id="EDITOR.LIGHTNESS" />} aria-label="Min/Max Output (Midtones)" placement="right">
            <i className="fa fa-sliders-h"></i>
        </LightTooltip>
    </Grid>
    <Grid item xs className="pb-0">
        <Slider
            min={0}
            max={255}
            valueLabelDisplay="auto"
            value={minMaxInput}
            onChange={setLevelsMinMaxInput}
            defaultValue={[0, 255]}
        />
        <Slider
            min={0.01}
            max={4.0}
            step={0.01}
            valueLabelDisplay="auto"
            value={midtones}
            scale={(value) => 2 ** value}
            onChange={setLevelsMidtones}
            getAriaValueText={valueLabelFormat}
            valueLabelFormat={valueLabelFormat}
            defaultValue={1.0}
        />
        <Slider
            min={0}
            max={255}
            valueLabelDisplay="auto"
            value={minMaxOutput}
            onChange={setLevelsMinMaxOutput}
            defaultValue={[0, 255]}
        />
    </Grid>
    <Grid item>
        <LightTooltip title={<FormattedMessage id="TEXT.RESET" />} aria-label="Reset" placement="right">
            <i onClick={() => {
                setLevelsMinMaxInput(null, [0, 255]);
                setLevelsMidtones(null, 0);
                setLevelsMinMaxOutput(null, [0, 255]);
                setLevels([0, 1, 1, 0, 1]);

                layer.removeFilter(layer.getFilterByType(LevelsFilter)[0]);
                layer.thumb.render();
            }} className={`fa fa-undo cursor-pointer ${lightMode ? "text-light" : "text-dark"}`}></i>
        </LightTooltip>
    </Grid>
</Grid>)
}