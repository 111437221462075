import React, {useMemo, useState} from "react";
import {useHtmlClassService} from "../../_core/MetronicLayout";
import { Dialog, DialogTitle, DialogContent, Grid } from "@material-ui/core";
import { FormattedMessage } from "react-intl";
import * as pkg from "../../../../../package.json";

export function Footer() {
  const today = new Date().getFullYear();
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      footerClasses: uiService.getClasses("footer", true),
      footerContainerClasses: uiService.getClasses("footer_container", true)
    };
  }, [uiService]);

  const [openAbout, setOpenAbout] = useState(false);
  const [openContact, setOpenContact] = useState(false);

  return (
    <div
      className={`footer bg-white py-4 d-flex flex-lg-column  ${layoutProps.footerClasses}`}
      id="kt_footer"
    >
      <div
        className={`${layoutProps.footerContainerClasses} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        <div className="text-dark order-2 order-md-1">
          <span className="text-muted font-weight-bold mr-2">{today.toString()}</span> &copy;{" "}
          <a
            href="https://inkseps.com"
            target="_blank"
            rel="noopener noreferrer"
            className="text-dark-75 text-hover-primary"
          >
            InkSeps
          </a>
        </div>
        <div className="nav nav-dark order-1 order-md-2">
          <span
            onClick={() => setOpenAbout(true)}
            className="nav-link pr-3 pl-0 cursor-pointer"
          >
            <FormattedMessage id="TEXT.ABOUT" />
          </span>
          <span
            onClick={() => setOpenContact(true)}
            className="nav-link pl-3 pr-3 cursor-pointer"
          >
            <FormattedMessage id="TEXT.CONTACT" />
          </span>
          <a href="https://discord.gg/kdMAqbGBSP" target="_blank" rel="noreferrer noopener" className="nav-link pl-3 pr-0 cursor-pointer">
            <i className="fab fa-discord mr-1"></i> Discord
          </a>
        </div>
      </div>
      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={openAbout}
        onClose={() => {}}
      >
        <DialogTitle>
          <Grid container direction="row" alignItems="center" alignContent="center" >
            <Grid item><FormattedMessage id="TEXT.ABOUT" /> InkSeps</Grid>
            <Grid item xs className="text-right"><i onClick={() => setOpenAbout(false)} className="fa fa-times text-dark cursor-pointer"></i></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <p className="px-5 text-justify">
            <FormattedMessage id="FOOTER.ABOUT_1" /><br /><br />
            <FormattedMessage id="FOOTER.ABOUT_2" values={{ a: (chunks) => (<a href="https://www.advancedtshirts.com/coreldraw-video-tutorials/">{chunks}</a>)}} />
          </p>
          <h6 className="small text-center mt-7">v{pkg.version}</h6>
        </DialogContent>
      </Dialog>
      <Dialog
        maxWidth="xs"
        fullWidth={true}
        open={openContact}
        onClose={() => {}}
      >
        <DialogTitle>
          <Grid container direction="row" alignItems="center" alignContent="center" >
            <Grid item><FormattedMessage id="TEXT.CONTACT" /> InkSeps</Grid>
            <Grid item xs className="text-right"><i onClick={() => setOpenContact(false)} className="fa fa-times text-dark cursor-pointer"></i></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <div className="text-center px-5">
            <p className="mb-1">
              <FormattedMessage id="FOOTER.CONTACT.EMAIL" />
            </p>
            <h4>contact@inkseps.com</h4>

            <h3 className="text-muted my-6">
              <FormattedMessage id="TEXT.OR" />
            </h3>

            <p className="mb-1">
              <FormattedMessage id="FOOTER.CONTACT.DISCORD" />
            </p>
            <h3>
              <a href="https://discord.gg/kdMAqbGBSP" target="_blank" rel="noreferrer noopener">
                <i className="fab fa-4x fa-discord" style={{"color":"#5865F2"}}></i><br />
                Discord
              </a>
            </h3>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
