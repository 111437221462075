/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector } from "react-redux";
import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { ComingSoon } from "../../../../../app/components/ComingSoon";
import { FormattedMessage } from "react-intl";

export function UserProfileDropdown() {
  const { user } = useSelector((state) => state.auth);
  const [firstName, lastName] = user.name.split(' ');

  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div
          className={
            "btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2"
          }
        >
          <span className="text-muted font-weight-bold font-size-base d-none d-md-inline mr-1">
            <FormattedMessage id="TEXT.HI" />
          </span>{" "}
          <span className="text-dark-50 font-weight-bolder font-size-base d-none d-md-inline mr-3">
            {firstName}
          </span>
          <span className="symbol symbol-35 symbol-light-success">
            <span className="symbol-label font-size-h5 font-weight-bold">
              {firstName.toUpperCase().substr(0, 1) + (lastName ? lastName.toUpperCase().substr(0, 1) : '')}
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="p-0 m-0 dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl">
        <div
          className="d-flex align-items-center justify-content-between flex-wrap p-8 bgi-size-cover bgi-no-repeat rounded-top"
          style={{
            backgroundImage: `url(${toAbsoluteUrl(
              "/media/misc/bg-1.jpg"
            )})`,
          }}
        >
          <div className="symbol bg-white-o-15 mr-3">
            <span className="symbol-label text-success font-weight-bold font-size-h4">
              {firstName.toUpperCase().substr(0, 1) + (lastName ? lastName.toUpperCase().substr(0, 1) : '')}
            </span>
            {/*<img alt="Pic" className="hidden" src={user.pic} />*/}
          </div>
          <div className="text-white m-0 flex-grow-1 mr-3 font-size-h5">
            {firstName}
          </div>
          {/*<span className="label label-success label-lg font-weight-bold label-inline">
            3 messages
          </span>*/}
        </div>

        <div className="navi navi-spacer-x-0 pt-5">
          <ComingSoon dark={true}>
            <Link to="/user-profile" className="navi-item px-8 cursor-pointer">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon2-calendar-3 text-success" />
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    <FormattedMessage id="USER.MY_PROFILE" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.MY_PROFILE_TEXT" />
                  </div>
                </div>
              </div>
            </Link>

            <a className="navi-item px-8">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <i className="flaticon-medal text-primary"></i>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold cursor-pointer">
                    <FormattedMessage id="USER.MY_MEMBERSHIP" />
                  </div>
                  <div className="text-muted">
                    <FormattedMessage id="USER.MY_MEMBERSHIP_TEXT" />
                  </div>
                </div>
              </div>
            </a>
          </ComingSoon>

          <div className="navi-separator mt-3"></div>

          <div className="navi-footer px-8 py-5">
            <span></span>
            <Link
              to="/logout"
              className="btn btn-light-primary font-weight-bold"
            >
              <FormattedMessage id="TEXT.LOGOUT" />
            </Link>
          </div>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}
