import React, { useEffect } from "react";
import { Grid, Popover } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { FormattedMessage } from "react-intl";

export function LayerMerge({lightMode, layer, setCurrentLayer, getCompactLayerList, disallowMergeInto}) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    function handleMergeClick(event) {
      setAnchorEl(event.currentTarget);
    }
    function handleMergeClose() {
      setAnchorEl(null);
    }

    useEffect(() => {
        handleMergeClose();
    }, [layer]);

    // no layer
    if (!layer) {
        return <></>;
    }

    return (<>
<Grid item xs aria-describedby={"merge-into-popover"} variant="contained" onClick={handleMergeClick}>
    <LightTooltip title={<FormattedMessage id="TEXT.MERGE_INTO" />} aria-label="Merge Into" placement="bottom" className="w-100 text-center cursor-pointer">
        <i className={`${lightMode ? "text-light" : "text-dark"} fa fa-layer-group`}></i>
    </LightTooltip>
</Grid>  
{!!getCompactLayerList && <Popover
    id={"merge-into-popover"}
    open={open}
    anchorEl={anchorEl}
    onClose={handleMergeClose}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
    }}
    transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
    }}
>
    <div className="p-3">{getCompactLayerList((selectedLayer) => {
        layer.mergeInto(selectedLayer, 100);
        setCurrentLayer(selectedLayer);
    }, disallowMergeInto, true)}</div>
</Popover>}
</>)
}