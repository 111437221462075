import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import ColorPicker from "../ColorPicker";
import { OverlayFilter, hslaToRgba, rgbaToHsla } from "@flat-gl/core";
import { FormattedMessage } from "react-intl";
import { MemberBadge } from "../MemberBadge";

const rgbaStringToColorMap = {
    "[255,0,0,1]": "Red",
    "[255,128,0,1]": "Orange",
    "[255,255,0,1]": "Yellow",
    "[128,255,0,1]": "Lime",
    "[0,255,0,1]": "Green",
    "[0,255,128,1]": "Mint",
    "[0,255,255,1]": "Cyan",
    "[0,128,255,1]": "Azure",
    "[0,0,255,1]": "Blue",
    "[128,0,255,1]": "Violet",
    "[255,0,255,1]": "Magenta",
    "[255,0,128,1]": "Rose",
    "[128,128,128,1]": "Gray",
    "[0,0,0,1]": "Black",
};

export function LayerColor({lightMode, layer, refreshLayers}) {
    const [color, setColor] = useState({h: 0, s: 0, l: 0, a: 1, rgba: [0, 0, 0, 1]});
    const [visible, setVisible] = useState(true);

    const handlePickerChangeComplete = (newColor) => {
        const rgba = hslaToRgba(newColor.hsl.h / 360, newColor.hsl.s, newColor.hsl.l, newColor.hsl.a);

        const rgbaRounded = [
            Math.ceil(rgba[0] * 255),
            Math.ceil(rgba[1] * 255),
            Math.ceil(rgba[2] * 255),
            rgba[3]
        ];
        const originalRgbaRounded = [
            Math.ceil(layer.originalOverlayColor[0] * 255),
            Math.ceil(layer.originalOverlayColor[1] * 255),
            Math.ceil(layer.originalOverlayColor[2] * 255),
            layer.originalOverlayColor[3]
        ];
        
        // update overlay filter
        layer.setFilter(OverlayFilter, [rgba]);
        
        // update layer name if necessary 
        layer.name = JSON.stringify(rgbaRounded) === JSON.stringify(originalRgbaRounded) ? rgbaStringToColorMap[JSON.stringify(rgbaRounded)] : "CustomColor";

        // set color and trigger layer array refresh
        setColor({...newColor.hsl, rgba});
        refreshLayers();
    };

    const toggleFilterVisible = () => {
        // toggle OverlayFilter
        layer.filters.forEach((filter) => {
            if (filter instanceof OverlayFilter) {
                filter.enabled = !filter.enabled;
                //layer.thumb.render();
                setVisible(filter.enabled);
            }
        });
    };

    useEffect(() => {
        if (!layer) {
            return;
        }

        const filter = layer.getFilterByType(OverlayFilter)[0];
        if (filter) {
            const hsla = rgbaToHsla(filter.rgba);
            setColor({h: hsla[0] * 360, s: hsla[1], l: hsla[2], a: hsla[3], rgba: filter.rgba});
            setVisible(filter.enabled);
        } else {
            setColor({h: 0, s: 0, l: 0, a: 1, rgba: [0, 0, 0, 1]});
            setVisible(true);
        }
    }, [layer]);

    // no layer
    if (!layer) {
        return <></>;
    }

    // if layer has no overlay filter then this adjustment is now allowed
    const filter = layer.getFilterByType(OverlayFilter)[0];
    if (!filter) {
        return <></>;
    }


    return (<Grid container spacing={1} alignItems="center" className="pt-2 border-top border-themed">
    <Grid item xs className="pb-0">
        <ColorPicker color={color} setColor={handlePickerChangeComplete}></ColorPicker>
    </Grid>
    <Grid item className="text-center">
        <MemberBadge placement="static" />
        <br /><br />

        <LightTooltip title={visible ? <FormattedMessage id="TEXT.HIDE" /> : <FormattedMessage id="TEXT.SHOW" />} aria-label={visible ? 'Hide' : 'Show'} placement="right">
            <i onClick={toggleFilterVisible} className={`fa fa-fw ${visible ? 'fa-eye' : 'fa-eye-slash'} cursor-pointer ${lightMode ? "text-light" : "text-dark"}`}></i>
        </LightTooltip>
        <br /><br />

        <LightTooltip title={<FormattedMessage id="TEXT.RESET" />} aria-label="Reset" placement="right">
            <i onClick={() => {
                const hsla = rgbaToHsla(layer.originalOverlayColor);
                handlePickerChangeComplete({hsl: {h: hsla[0] * 360, s: hsla[1], l: hsla[2], a: hsla[3]}});
            }} className={`fa fa-fw fa-undo cursor-pointer ${lightMode ? "text-light" : "text-dark"}`}></i>
        </LightTooltip>
    </Grid>
</Grid>)
}