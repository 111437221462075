import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { rgbaToCwba, OverlayFilter } from "@flat-gl/core";
import { FormattedMessage } from "react-intl";

export function LayerCWB({layer, layers}) {
    const [cwba, setCwba] = useState(null); //[[0, 0, 0, 0, 0, 0], 100, 0, 1]);

    useEffect(() => {
        if (!layer) {
            return;
        }
        
        const filter = layer.getFilterByType(OverlayFilter)[0];
        if (filter) {
            setCwba(rgbaToCwba(filter.rgba, 100));
        } else {
            setCwba(null);
        }
    }, [layer, layers]);

    // no layer
    if (!layer || cwba === null) {
        return <></>;
    }

    // define color labels
    const colors = ["R", "Y", "G", "C", "B", "M"];
    const colorsFull = ["Red", "Yellow", "Green", "Cyan", "Blue", "Magenta"];
    const components = [];

    // color(s)
    let color = 0;
    cwba[0].forEach((value, index) => {
        if (value > 0) {
            color += Math.round(value * 100);
            components.push(<Grid item xs key={colorsFull[index]}>
                <LightTooltip title={colorsFull[index]} aria-label={colorsFull[index]} placement="right">
                    <span><span className="text-muted">{colors[index]}</span> <span className="text-light">{Math.round(value * 100)}%</span></span>
                </LightTooltip>
            </Grid>);
        }
    });

    // account for rounding edge cases (eg. color ends in .2 while white/black ends with .4, this adds up to 1.0 but when rounded we get 0.0)
    let white = Math.round(cwba[1] * 100);
    let black = Math.round(cwba[2] * 100);

    // over 100?
    if (color + white + black > 100) {
        // which value was closer to being rounded down
        if (cwba[1] < cwba[2]) {
            white--;
        } else {
            black--;
        }
    }
    // under 100?
    if (color + black + white < 100) {
        // which value was closer to being rounded up
        if (cwba[1] > cwba[2]) {
            white++;
        } else {
            black++;
        }
    }

    // white
    components.push(<Grid item xs key="White">
        <LightTooltip title="White" aria-label="White" placement="right">
            <span><span className="text-muted">W</span> <span className="text-light">{`${white}%`}</span></span>
        </LightTooltip>
    </Grid>);

    // black
    components.push(<Grid item xs key="Black">
        <LightTooltip title="Black" aria-label="Black" placement="right">
            <span><span className="text-muted">B</span> <span className="text-light">{`${black}%`}</span></span>
        </LightTooltip>
    </Grid>);

    return (<Grid container direction="row" alignItems="center" alignContent="center" className="text-center">
        <Grid item>
            <LightTooltip title={<FormattedMessage id="EDITOR.COLOR_MATCHING" />} aria-label="CWB Color Matching Formula" placement="right">
                <i className="fa fa-flask"></i>
            </LightTooltip>
        </Grid>
        {components}
    </Grid>)
}
