import React from 'react';
import reactCSS from 'reactcss';

export var HuePointer = function HuePointer() {
  var styles = reactCSS({
    'default': {
        picker: {
            width: '8px',
            height: '22px',
            borderRadius: '3px',
            border: '2px solid #FFF',
            boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.3)',
            transform: 'translate(-4px, -1px)'
        }
    }
  });

  return React.createElement('div', { style: styles.picker });
};

export default HuePointer;