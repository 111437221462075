import React, {useEffect, useRef} from "react";

export function LayerThumbnail({flatGl, layer, className = "", style = {}, useSurface = false, mirror = true}) {
    const divRef = useRef(null);
    
    useEffect(() => {
        if (divRef) {
            if (useSurface) {
                // remove mirror from preview surface
                if (!mirror) {
                    flatGl.canvas.mirroredSurfaces = [];
                    flatGl.previewSurfaceA.mirroredSurfaces = [];
                }

                // clone to preview surface
                layer.cloneToSurface(flatGl.previewSurfaceA);
                flatGl.previewSurfaceA.stage.children[0].filters = layer.filters;
                flatGl.previewSurfaceA.setContainer(divRef.current);
            } else {
                layer.setThumbnailContainer(divRef.current);
            }
        }

        return () => {
            if (useSurface) {
                // place mirror back in preview surface
                if (!mirror) {
                    flatGl.canvas.mirrorSurface(flatGl.previewSurfaceA); //flatGl.previewSurfaceA.mirroredSurfaces = [flatGl.canvas];
                }
                flatGl.previewSurfaceA.unsetContainer();
            } else {
                layer.unsetThumbnailContainer();
            }
        }
    }, [flatGl, layer, useSurface, mirror]);

    if (!layer) {
        return <i className="fa fa-ban text-danger"></i>
    }

    return (<div ref={divRef} className={className} style={style}></div>)
}