import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { CurvesEditor } from "../CurvesEditor";
import { CurvesFilter } from "@flat-gl/core";
import { FormattedMessage } from "react-intl";

export function LayerCurves({lightMode, layer}) {
    const [curveNodes, setCurveNodes] = useState([[0, 0], [1, 1]]);
    const [curveLinear, setCurveLinear] = useState(true);

    // set layer filter methods
    const updateCurveNodes = (nodes) => {
        layer.setFilter(CurvesFilter, [nodes, curveLinear]);
        setCurveNodes(nodes);
    };
    const updateCurveLinear = (value) => {
        layer.setFilter(CurvesFilter, [curveNodes, value]);
        setCurveLinear(value);
    }
    const resetCurveNodes = () => {
        layer.removeFilter(layer.getFilterByType(CurvesFilter)[0]);
        setCurveNodes([[0, 0], [1, 1]]);
        setCurveLinear(true);
    };
    
    useEffect(() => {
        if (!layer) {
            return;
        }

        const filter = layer.getFilterByType(CurvesFilter)[0];
        if (filter) {
            setCurveNodes(filter.nodes);
            setCurveLinear(filter.linearInterpolated);
        } else {
            setCurveNodes([[0, 0], [1, 1]]);
            setCurveLinear(true);
        }
    }, [layer]);

    // no layer
    if (!layer) {
        return <></>;
    }

    return (
<Grid container spacing={1} alignItems="center" className="py-2 border-top border-themed">
    <Grid item xs className="text-center">
        <CurvesEditor lightMode={lightMode} linear={curveLinear} curveNodes={curveNodes} setCurveNodes={updateCurveNodes} />
    </Grid>
    <Grid item className="text-center">
        <LightTooltip title={curveLinear ? <FormattedMessage id="EDITOR.NON_LINEAR" /> : <FormattedMessage id="EDITOR.LINEAR" />} aria-label={curveLinear ? "Set Curve Non-linear" : "Set Curve Linear"} placement="right">
            <span onClick={() => updateCurveLinear(!curveLinear)} className="cursor-pointer">
                <i className={`fa fa-bezier-curve mb-4 ${curveLinear ? "opacity-50" : ""} ${(lightMode ? "text-light" : "text-dark")}`} />
            </span>
        </LightTooltip>
        <br />

        <LightTooltip title={<FormattedMessage id="TEXT.RESET" />} aria-label="Reset" placement="right">
            <span onClick={resetCurveNodes} className="cursor-pointer">
                <i className={`fa fa-undo ${lightMode ? "text-light" : "text-dark"} mb-4`} />
            </span>
        </LightTooltip>
    </Grid>
</Grid>)
}