import React from "react";
import { Redirect, useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import DragAndDrop from "../components/DragAndDrop";

export function DashboardPage({flatGl, currentLayer, viewMode, setViewMode, setPageLoader}) {
  const history = useHistory();
  const handleDrop = (files) => {
    setPageLoader(true);

    // open file
    flatGl.open(files[0]).then(() => {
      if (viewMode !== "fullPreview") {
        // responsive view mode based on image ratio
        if (flatGl.canvas.width / flatGl.canvas.height > 1.6666) {
          setViewMode("topBottom");
        } else {
          setViewMode("leftRight");
        }
      }

      // redirect to editor page
      setPageLoader(false);
      history.push("/editor");
    });
  };

  if (currentLayer !== null) {
    return <Redirect to="/editor"></Redirect>
  }

  const dragAndDropStyle = {
    width: '100%',
    height: '100%',
  };

  return <div className="d-flex flex-fill flex-column flex-xl-row p-5">
    <div className="d-flex flex-fill" style={{minWidth:"70%", minHeight:"50%"}}>
      <div className="d-flex flex-fill flex-column">
        <div className="font-weight-bold mb-4">
          <h2><FormattedMessage id="DASHBOARD.STEP_2" /></h2>
          <p style={{maxWidth:"180px"}}><FormattedMessage id="DASHBOARD.UPLOAD_YOUR_IMAGE" /></p>
        </div>
        <div className="d-flex flex-column flex-grow-1">
          <DragAndDrop handleDrop={handleDrop}>
            <div className="d-flex flex-column align-items-center justify-content-center" style={dragAndDropStyle}>
              <i className="fa fa-4x fa-upload"></i>
              <h5 className="text-muted mt-2"><FormattedMessage id="DASHBOARD.DRAG_AND_DROP" /></h5>
            </div>
          </DragAndDrop>
        </div>
      </div>
    </div>
    <div className="d-flex flex-fill">
      <div className="d-flex flex-fill flex-row flex-xl-column pl-0 pl-xl-5 pt-5 pt-xl-0">
        <div className="font-weight-bold mb-0 mb-xl-4" style={{minWidth:"20%"}}>
          <h2><FormattedMessage id="DASHBOARD.FIRST_TIME" /></h2>
          <p className="w-100" style={{maxWidth:"200px"}}><FormattedMessage id="DASHBOARD.HELPFUL_TIPS" /></p>
        </div>
        <div className="d-flex flex-row flex-xl-column align-items-start">
          <div className="col mx-4 mx-xl-0 m-0 p-0 pb-xl-6 dashboard-tip">
            <p className="m-0 text-justify"><FormattedMessage id="DASHBOARD.INTERLOCK_WARNING" /></p>
            
            <p className="m-0 pt-3 pb-2 pb-xl-0 text-center">
              <a href="https://www.youtube.com/watch?v=9Cy_x5jDlU0&list=PL5-TSr51OI-6w4MmLB-IUPv7GiAiyngVw" target="_blank" rel="noopener noreferrer" className="btn btn-outline-danger"><i className="fab fa-youtube pr-1" /> <FormattedMessage id="DASHBOARD.OPEN_YOUTUBE" /></a>
            </p>
          </div>

          <div className="d-none d-xl-block w-100 border-bottom border-themed"></div>

          <div className="col mx-4 mx-xl-0 m-0 p-0 pt-xl-6 dashboard-tip">
            <p className="m-0 text-justify"><FormattedMessage id="DASHBOARD.FASTEST_WAY" /></p>

            <iframe className="w-100 mt-3 pb-2 pb-xl-0" height="268" src="https://www.youtube-nocookie.com/embed/9Cy_x5jDlU0" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
}
