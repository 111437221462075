import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../_metronic/_helpers";

export default function TermsPage(props) {
  //const { intl } = props;

  return (
<div className="d-flex flex-column flex-root" style={{maxHeight:"100%"}}>
    {/*begin::Login*/}
    <div className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white" style={{maxHeight:"100%"}}>
      {/*begin::Aside*/}
      <div
        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/bg/auth.jpg")})`,
        }}
      >
        {/*begin: Aside Container*/}
        <div className="d-flex flex-row-fluid flex-column justify-content-between">
          {/* start:: Aside header */}
          <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
            <img
              alt="Logo"
              className="max-h-70px"
              src={toAbsoluteUrl("/media/logos/logo-letter-invert.png")}
            />
          </Link>
          {/* end:: Aside header */}

          {/* start:: Aside content */}
          <div className="flex-column-fluid d-flex flex-column justify-content-center">
            <h3 className="font-size-h1 mb-5 text-white">
              Terms and Conditions
            </h3>
          </div>
          {/* end:: Aside content */}

          {/* start:: Aside footer for desktop */}
          <div className="d-none flex-column-auto d-lg-flex justify-content-between mt-10">
            <div className="opacity-70 font-weight-bold	text-white">
              &copy; 2021 InkSeps
            </div>
            <div className="d-flex">
              <Link to="/privacy-policy" className="text-white">
                Privacy
              </Link>
              <Link to="/terms-and-conditions" className="text-white ml-10">
                Terms
              </Link>
              <a
                href="https://inkseps.com/contact"
                target="_blank"
                rel="noopener noreferrer"
                className="text-white ml-10"
              >
                Contact
              </a>
            </div>
          </div>
          {/* end:: Aside footer for desktop */}
        </div>
        {/*end: Aside Container*/}
      </div>
      {/*begin::Aside*/}

      {/*begin::Content*/}
      <div className="d-flex flex-fill p-7 overflow-auto">

        {/* begin::Content body */}
        <div className="mt-10 mt-lg-0">
            <p>
                <strong>Agreement between User and https://inkseps.com</strong><br/>
                Welcome to https://inkseps.com. The https://inkseps.com website (the &#34;Site&#34;) is comprised of&#160;<br/>
                various web pages operated by CWB Technologies LLC (&#34;CWB Technologies&#34;).&#160;<br/>
                https://inkseps.com is offered to you conditioned on your acceptance without modification of the&#160;<br/>
                terms, conditions, and notices contained herein (the &#34;Terms&#34;). Your use of https://inkseps.com&#160;<br/>
                constitutes your agreement to all such Terms. Please read these terms carefully, and keep a copy&#160;<br/>
                of them for your reference.
            </p>
            <p>
                https://inkseps.com is a Photo Separator &amp; Editor Site.
            </p>
            <p>
                This website is used to separate, analyze and edit photos for use in screen printing. InkSeps is free&#160;<br/>
                to use with limitations, paid memberships are offered to unlock all capabilities.
            </p>

            <p>
                <strong>Privacy</strong>&#160;&#160;<br/>
                Your use of https://inkseps.com is subject to CWB Technologies's Privacy Policy. Please review&#160;<br/>
                our Privacy Policy, which also governs the Site and informs users of our data collection practices.
            </p>

            <p>
                <strong>Electronic Communications</strong>&#160;&#160;<br/>
                Visiting https://inkseps.com or sending emails to CWB Technologies constitutes electronic&#160;<br/>
                communications. You consent to receive electronic communications and you agree that all&#160;<br/>
                agreements, notices, disclosures and other communications that we provide to you electronically,&#160;<br/>
                via email and on the Site, satisfy any legal requirement that such communications be in writing.
            </p>

            <p>
                <strong>Your Account</strong>&#160;&#160;<br/>
                If you use this site, you are responsible for maintaining the confidentiality of your account and&#160;<br/>
                password and for restricting access to your computer, and you agree to accept responsibility for&#160;<br/>
                all activities that occur under your account or password. You may not assign or otherwise transfer&#160;<br/>
                your account to any other person or entity. You acknowledge that CWB Technologies is not&#160;<br/>
                responsible for third party access to your account that results from theft or misappropriation of&#160;<br/>
                your account. CWB Technologies and its associates reserve the right to refuse or cancel service,&#160;<br/>
                terminate accounts, or remove or edit content in our sole discretion.
            </p>

            <p>
                <strong>Children Under Thirteen</strong>&#160;&#160;<br/>
                CWB Technologies does not knowingly collect, either online or offline, personal information from&#160;<br/>
                persons under the age of thirteen. If you are under 18, you may use https://inkseps.com only with&#160;<br/>
                permission of a parent or guardian.
            </p>

            <p>
                <strong>Links to Third Party Sites/Third Party Services</strong>&#160;&#160;<br/>
                https://inkseps.com may contain links to other websites (&#34;Linked Sites&#34;). The Linked Sites are not&#160;<br/>
                under the control of CWB Technologies and CWB Technologies is not responsible for the&#160;<br/>
                contents of any Linked Site, including without limitation any link contained in a Linked Site, or any&#160;<br/>
                changes or updates to a Linked Site. CWB Technologies is providing these links to you only as a&#160;<br/>
                convenience, and the inclusion of any link does not imply endorsement by CWB Technologies of&#160;<br/>
                the site or any association with its operators.
            </p>
            <p>
                Certain services made available via https://inkseps.com are delivered by third party sites and&#160;<br/>
                organizations. By using any product, service or functionality originating from the&#160;<br/>
                https://inkseps.com domain, you hereby acknowledge and consent that CWB Technologies may&#160;<br/>
                share such information and data with any third party with whom CWB Technologies has a&#160;<br/>
                contractual relationship to provide the requested product, service or functionality on behalf of&#160;<br/>
                https://inkseps.com users and customers.
            </p>

            <p>
                <strong>No Unlawful or Prohibited Use/Intellectual Property&#160;</strong>&#160;<br/>
                You are granted a non-exclusive, non-transferable, revocable license to access and use&#160;<br/>
                https://inkseps.com strictly in accordance with these terms of use.&#160;As a condition of your use of the&#160;<br/>
                Site, you warrant to CWB Technologies that you will not use the Site for any purpose that is&#160;<br/>
                unlawful or prohibited by these Terms. You may not use the Site in any manner which could&#160;<br/>
                damage, disable, overburden, or impair the Site or interfere with any other party's use and&#160;<br/>
                enjoyment of the Site. You may not obtain or attempt to obtain any materials or information&#160;<br/>
                through any means not intentionally made available or provided for through the Site.
            </p>
            <p>
                All content included as part of the Service, such as text, graphics, logos, images, as well as the&#160;<br/>
                compilation thereof, and any software used on the Site, is the property of CWB Technologies or&#160;<br/>
                its suppliers and protected by copyright and other laws that protect intellectual property and&#160;<br/>
                proprietary rights. You agree to observe and abide by all copyright and other proprietary notices,&#160;<br/>
                legends or other restrictions contained in any such content and will not make any changes thereto.
            </p>
            <p>
                You will not modify, publish, transmit, reverse engineer, participate in the transfer or sale, create&#160;<br/>
                derivative works, or in any way exploit any of the content, in whole or in part, found on the Site.&#160;<br/>
                CWB Technologies content is not for resale. Your use of the Site does not entitle you to make any&#160;<br/>
                unauthorized use of any protected content, and in particular you will not delete or alter any&#160;<br/>
                proprietary rights or attribution notices in any content. You will use protected content solely for&#160;<br/>
                your personal use, and will make no other use of the content without the express written&#160;<br/>
                permission of CWB Technologies and the copyright owner. You agree that you do not acquire any&#160;<br/>
                ownership rights in any protected content. We do not grant you any licenses, express or implied,&#160;<br/>
                to the intellectual property of CWB Technologies or our licensors except as expressly authorized&#160;<br/>
                by these Terms.
            </p>

            <p>
                <strong>International Users</strong>&#160;&#160;<br/>
                The Service is controlled, operated and administered by CWB Technologies from our offices&#160;<br/>
                within the USA. If you access the Service from a location outside the USA, you are responsible&#160;<br/>
                for compliance with all local laws. You agree that you will not use the CWB Technologies Content&#160;<br/>
                accessed through https://inkseps.com in any country or in any manner prohibited by any applicable&#160;<br/>
                laws, restrictions or regulations.
            </p>

            <p>
                <strong>Indemnification</strong>&#160;&#160;<br/>
                You agree to indemnify, defend and hold harmless CWB Technologies, its officers, directors,&#160;<br/>
                employees, agents and third parties, for any losses, costs, liabilities and expenses (including&#160;<br/>
                reasonable attorney's fees) relating to or arising out of your use of or inability to use the Site or&#160;<br/>
                services, any user postings made by you, your violation of any terms of this Agreement or your&#160;<br/>
                violation of any rights of a third party, or your violation of any applicable laws, rules or regulations.&#160;<br/>
                CWB Technologies reserves the right, at its own cost, to assume the exclusive defense and control&#160;<br/>
                of any matter otherwise subject to indemnification by you, in which event you will fully cooperate&#160;<br/>
                with CWB Technologies in asserting any available defenses.
            </p>

            <p>
                <strong>Arbitration</strong>&#160;&#160;<br/>
                In the event the parties are not able to resolve any dispute between them arising out of or&#160;<br/>
                concerning these Terms and Conditions, or any provisions hereof, whether in contract, tort, or&#160;<br/>
                otherwise at law or in equity for damages or any other relief, then such dispute shall be resolved&#160;<br/>
                only by final and binding arbitration pursuant to the Federal Arbitration Act, conducted by a single&#160;<br/>
                neutral arbitrator and administered by the American Arbitration Association, or a similar arbitration&#160;<br/>
                service selected by the parties, in a location mutually agreed upon by the parties. The arbitrator's&#160;<br/>
                award shall be final, and judgment may be entered upon it in any court having jurisdiction. In the&#160;<br/>
                event that any legal or equitable action, proceeding or arbitration arises out of or concerns these&#160;<br/>
                Terms and Conditions, the prevailing party shall be entitled to recover its costs and reasonable&#160;<br/>
                attorney's fees. The parties agree to arbitrate all disputes and claims in regards to these Terms and&#160;<br/>
                Conditions or any disputes arising as a result of these Terms and Conditions, whether directly or&#160;<br/>
                indirectly, including Tort claims that are a result of these Terms and Conditions. The parties agree&#160;<br/>
                that the Federal Arbitration Act governs the interpretation and enforcement of this provision. The&#160;<br/>
                entire dispute, including the scope and enforceability of this arbitration provision shall be&#160;<br/>
                determined by the Arbitrator. This arbitration provision shall survive the termination of these Terms&#160;<br/>
                and Conditions.
            </p>

            <p>
                <strong>Liability Disclaimer</strong>&#160;&#160;<br/>
                THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR&#160;<br/>
                AVAILABLE THROUGH THE SITE MAY INCLUDE INACCURACIES OR&#160;<br/>
                TYPOGRAPHICAL ERRORS. CHANGES ARE PERIODICALLY ADDED TO THE&#160;<br/>
                INFORMATION HEREIN. CWB TECHNOLOGIES LLC AND/OR ITS SUPPLIERS MAY&#160;<br/>
                MAKE IMPROVEMENTS AND/OR CHANGES IN THE SITE AT ANY TIME.
            </p>
            <p>
                CWB TECHNOLOGIES LLC AND/OR ITS SUPPLIERS MAKE NO&#160;<br/>
                REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,&#160;<br/>
                TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,&#160;<br/>
                SERVICES AND RELATED GRAPHICS CONTAINED ON THE SITE FOR ANY&#160;<br/>
                PURPOSE. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL&#160;<br/>
                SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED&#160;<br/>
                GRAPHICS ARE PROVIDED &#34;AS IS&#34; WITHOUT WARRANTY OR CONDITION OF&#160;<br/>
                ANY KIND. CWB TECHNOLOGIES LLC AND/OR ITS SUPPLIERS HEREBY&#160;<br/>
                DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS&#160;<br/>
                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS,&#160;<br/>
                INCLUDING ALL IMPLIED WARRANTIES OR CONDITIONS OF&#160;<br/>
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT.&#160;&#160;<br/>
                TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT&#160;<br/>
                SHALL CWB TECHNOLOGIES LLC AND/OR ITS SUPPLIERS BE LIABLE FOR ANY&#160;<br/>
                DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL&#160;<br/>
                DAMAGES OR ANY DAMAGES WHATSOEVER INCLUDING, WITHOUT&#160;<br/>
                LIMITATION, DAMAGES FOR LOSS OF USE, DATA OR PROFITS, ARISING OUT OF&#160;<br/>
                OR IN ANY WAY CONNECTED WITH THE USE OR PERFORMANCE OF THE SITE,&#160;<br/>
                WITH THE DELAY OR INABILITY TO USE THE SITE OR RELATED SERVICES, THE&#160;<br/>
                PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY&#160;<br/>
                INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS&#160;<br/>
                OBTAINED THROUGH THE SITE, OR OTHERWISE ARISING OUT OF THE USE OF&#160;<br/>
                THE SITE, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT&#160;<br/>
                LIABILITY OR OTHERWISE, EVEN IF CWB TECHNOLOGIES LLC OR ANY OF ITS&#160;<br/>
                SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES. BECAUSE&#160;<br/>
                SOME STATES/JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR&#160;<br/>
                LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES,&#160;<br/>
                THE ABOVE LIMITATION MAY NOT APPLY TO YOU. IF YOU ARE DISSATISFIED&#160;<br/>
                WITH ANY PORTION OF THE SITE, OR WITH ANY OF THESE TERMS OF USE,&#160;<br/>
                YOUR SOLE AND EXCLUSIVE REMEDY IS TO DISCONTINUE USING THE SITE.
            </p>
            <p>
                <strong>Termination/Access Restriction&#160;</strong>&#160;<br/>
                CWB Technologies reserves the right, in its sole discretion, to terminate your access to the Site&#160;<br/>
                and the related services or any portion thereof at any time, without notice. To the maximum extent&#160;<br/>
                permitted by law, this agreement is governed by the laws of the State of California and you hereby&#160;<br/>
                consent to the exclusive jurisdiction and venue of courts in California in all disputes arising out of or&#160;<br/>
                relating to the use of the Site. Use of the Site is unauthorized in any jurisdiction that does not give&#160;<br/>
                effect to all provisions of these Terms, including, without limitation, this section.
            </p>
            <p>
                You agree that no joint venture, partnership, employment, or agency relationship exists between&#160;<br/>
                you and CWB Technologies as a result of this agreement or use of the Site. CWB Technologies's&#160;<br/>
                performance of this agreement is subject to existing laws and legal process, and nothing contained&#160;<br/>
                in this agreement is in derogation of CWB Technologies's right to comply with governmental, court&#160;<br/>
                and law enforcement requests or requirements relating to your use of the Site or information&#160;<br/>
                provided to or gathered by CWB Technologies with respect to such use. If any part of this&#160;<br/>
                agreement is determined to be invalid or unenforceable pursuant to applicable law including, but&#160;<br/>
                not limited to, the warranty disclaimers and liability limitations set forth above, then the invalid or&#160;<br/>
                unenforceable provision will be deemed superseded by a valid, enforceable provision that most&#160;<br/>
                closely matches the intent of the original provision and the remainder of the agreement shall&#160;<br/>
                continue in effect.
            </p>
            <p>
                Unless otherwise specified herein, this agreement constitutes the entire agreement between the user&#160;<br/>
                and CWB Technologies with respect to the Site and it supersedes all prior or contemporaneous&#160;<br/>
                communications and proposals, whether electronic, oral or written, between the user and CWB&#160;<br/>
                Technologies with respect to the Site. A printed version of this agreement and of any notice given&#160;<br/>
                in electronic form shall be admissible in judicial or administrative proceedings based upon or&#160;<br/>
                relating to this agreement to the same extent and subject to the same conditions as other business&#160;<br/>
                documents and records originally generated and maintained in printed form. It is the express wish&#160;<br/>
                to the parties that this agreement and all related documents be written in English.
            </p>

            <p>
                <strong>Changes to Terms</strong>&#160;&#160;<br/>
                CWB Technologies reserves the right, in its sole discretion, to change the Terms under which&#160;<br/>
                https://inkseps.com is offered. The most current version of the Terms will supersede all previous&#160;<br/>
                versions. CWB Technologies encourages you to periodically review the Terms to stay informed of&#160;<br/>
                our updates.
            </p>

            <p>
                <strong>Contact Us</strong>&#160;&#160;<br/>
                CWB Technologies welcomes your questions or comments regarding the Terms:
            </p>
            <p>
                CWB Technologies LLC&#160;&#160;<br/>
                36676 Douglas Fir Court&#160;&#160;<br/>
                Singletown, California 96088
            </p>
            <p>
                Email Address:&#160;&#160;<br/>
                contact@inkseps.com
            </p>
            <p>
                Telephone number:&#160;&#160;<br/>
                18338535342
            </p>
            <p>
                Effective as of December 01, 2021
            </p>
        </div>
        {/*end::Content body*/}

        {/* begin::Mobile footer */}
        <div className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
          <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
            &copy; 2021 InkSeps
          </div>
          <div className="d-flex order-1 order-sm-2 my-2">
            {/*<Link to="/terms" className="text-dark-75 text-hover-primary">
              Privacy
            </Link>
            <Link
              to="/terms"
              className="text-dark-75 text-hover-primary ml-4"
            >
              Legal
            </Link>*/}
            <a
              href="https://inkseps.com/contact"
              target="_blank"
              rel="noopener noreferrer"
              className="text-white"
            >
              Contact
            </a>
          </div>
        </div>
        {/* end::Mobile footer */}
      </div>
      {/*end::Content*/}
    </div>
    {/*end::Login*/}
  </div>
  );
}
