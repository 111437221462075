import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { FormattedMessage } from "react-intl";

export function LayerVisibilityToggle({lightMode, layer}) {
    const [visible, setVisible] = useState(true);
    
    const toggleLayerVisibility = () => {
        layer.visible = !layer.visible;
        setVisible(layer.visible);
    };

    useEffect(() => {
        if (!layer) {
            return;
        }

        setVisible(layer.visible);
    }, [layer]);

    // no layer
    if (!layer) {
        return <></>;
    }

    return (<Grid item xs onClick={(e) => toggleLayerVisibility()}>
    <LightTooltip title={visible ? <FormattedMessage id="TEXT.HIDE" /> : <FormattedMessage id="TEXT.SHOW" />} aria-label={visible ? 'Hide' : 'Show'} placement="bottom" className="w-100 text-center cursor-pointer">
        <i className={`fa fa-fw ${visible ? 'fa-eye' : 'fa-eye-slash'} ${lightMode ? "text-light" : "text-dark"}`}></i>
    </LightTooltip>
</Grid>)
}