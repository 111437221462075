import React from "react";
import { Redirect } from "react-router-dom";
import Canvas from "../components/Canvas";
import { LayerThumbnail } from "../components/Layer/LayerThumbnail";

export const EditorPage = ({ flatGl, viewMode, currentLayer, shirtColor }) => {
  if (!currentLayer) {
    return <Redirect to="/"></Redirect>
  }

  // split view enabled?
  const splitView = (viewMode === "leftRight" || viewMode === "topBottom");

  return (<div className={`d-flex flex-fill w-100 h-100 ${viewMode === "leftRight" ? "flex-column-reverse" : "flex-row"}`}>
  {splitView && <div className={`d-flex ${viewMode === "topBottom" ? "flex-column h-100" : "flex-row w-100"}`} style={{width:"20px"}}>
    <div className={`d-flex ${viewMode === "topBottom" ? "h-50" : "w-50"}`}>
        <span className={`text-center w-100 font-weight-bold ${viewMode === "topBottom" ? "vertical-text" : ""}`}>{currentLayer?.name}</span>
    </div>
    <div className={`d-flex ${viewMode === "topBottom" ? "h-50" : "w-50"}`}>
      <span className={`text-center w-100 font-weight-bold ${viewMode === "topBottom" ? "vertical-text" : ""}`}>Preview</span>
    </div>
  </div>}
  <div className={`d-flex flex-fill ${viewMode === "leftRight" ? "flex-row" : "flex-column"}`}>
    {splitView && <div className="d-flex p-0 border checkered-background" style={{flex: "0.5"}}>
      {currentLayer && <LayerThumbnail flatGl={flatGl} layer={currentLayer} className="d-flex flex-fill" useSurface={true} />}
    </div>}
    <div className="d-flex p-0 border checkered-background" style={{flex: splitView ? "0.5" : "1"}}>
      <Canvas flatGl={flatGl} className="d-flex flex-fill p-0" style={{backgroundColor: `rgba(${shirtColor.join(',')})`}} />
    </div>
  </div>
</div>);
};
