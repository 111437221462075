import React from "react";
import { CustomPicker } from 'react-color';
import HuePointer from "./HuePointer";
import SaturationPointer from "./SaturationPointer";
import { Grid } from "@material-ui/core";
const { Saturation, Hue } = require('react-color/lib/components/common');

class CustomColorPicker extends React.Component {
    render() {
        const swatches = [];
        [
            "red",
            "orange",
            "yellow",
            "lime",
            "green",
            "mint",
            "cyan",
            "azure",
            "blue",
            "violet",
            "magenta",
            "rose",
        ].forEach((color, index) => {
            swatches.push(<Grid item key={color}>
                <span onClick={() => {
                    this.props.setColor({hsl: {h: index * 30, s: 1, l: 0.5, a: 1}})
                }} className={`color-chip circle ${color} mr-1 cursor-pointer`}></span>
            </Grid>)
        })
        

        return (<div className="mb-4 pt-1" style={{...this.props.wrapperStyle}}>
    <div style={{minHeight:"120px", position:"relative", overflow:"hidden", borderRadius:"4px 4px 0 0"}}>
        <Saturation {...this.props} pointer={SaturationPointer} onChange={(color) => {
            if (this.props.setColor) {
                // convert to hsl color space
                let s = color.s;
                let v = color.v;
                let l = (2 - s) * v / 2;
                if (l !== 0) {
                    if (l === 1) {
                        s = 0
                    } else if (l < 0.5) {
                        s = s * v / (l * 2)
                    } else {
                        s = s * v / (2 - l * 2)
                    }
                }
                s = Math.min(1.0, Math.max(0.0, s));
                l = Math.min(1.0, Math.max(0.0, l));
                
                // update state
                this.props.setColor({hsl: {h: this.props.color.h, s: s, l: l, a: this.props.color.a}});
            }
        }} />
    </div>
    <div className="pb-3" style={{backgroundColor:"#FFF", width:"100%"}}></div>
    <div style={{minHeight:"20px", position:"relative"}}>
        <Hue {...this.props} pointer={HuePointer} onChange={(color) => {
            if (this.props.setColor) {console.log(color);
                //const rgba = hslaToRgba([color.h / 360, color.s || 1, color.l, 1]);
                this.props.setColor({hsl: {h: color.h, s: this.props.hsl.s, l: this.props.hsl.l, a: this.props.color.a}});
            }
        }} />
    </div>
    <Grid container className="pl-2 pt-2 pr-1 m-0" style={{backgroundColor:"#FFF", width:"100%", borderRadius:"0 0 4px 4px"}}>
        {swatches}
    </Grid>
</div>)
    }
}

export default CustomPicker(CustomColorPicker);