import React from "react";
import { NavLink, useHistory } from "react-router-dom";
import { Grid, Popover } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

export function HeaderMenu({ flatGl, layoutProps, currentLayer, setPageLoader }) {
    const history = useHistory();
    
    const [downloadAnchorEl, setDownloadAnchorEl] = React.useState(null);
    const openDownload = Boolean(downloadAnchorEl);

    const [resetAnchorEl, setResetAnchorEl] = React.useState(null);
    const openReset = Boolean(resetAnchorEl);

    const [newAnchorEl, setNewAnchorEl] = React.useState(null);
    const openNew = Boolean(newAnchorEl);

    return <div
        id="kt_header_menu"
        className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`}
        {...layoutProps.headerMenuAttributes}
    >
        {/*begin::Header Nav*/}
        <ul className={`menu-nav ${layoutProps.ulClasses}`}>
            {/*begin::1 Level*/}
            {/*<li className={`menu-item menu-item-rel ${getMenuItemActive('/dashboard')}`}>
                <NavLink className="menu-link" to="/dashboard">
                    <span className="menu-text">Dashboard</span>
                </NavLink>
            </li>*/}
            {currentLayer && <>
                <li className={`menu-item menu-item-rel`}>
                    <span className="menu-link bg-success" aria-describedby={"download-confirmation"} variant="contained" onClick={(event) => setDownloadAnchorEl(event.currentTarget)}>
                        <span className="menu-text text-light"><i className="fa fa-download text-light mr-2" /> <FormattedMessage id="TEXT.DOWNLOAD" /></span>
                    </span>
                    <Popover
                        id={"download-confirmation"}
                        open={openDownload}
                        anchorEl={downloadAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className="p-3 text-center">
                            <Grid container alignItems="center">
                                <Grid item><i className="fa fa-exclamation-triangle text-warning pr-4"></i></Grid>
                                <Grid item xs>
                                    <p className="text-center">
                                        <strong><FormattedMessage id="TEXT.WARNING" /></strong> <FormattedMessage id="HEADER.DOWNLOAD_1" /><br />
                                        <FormattedMessage id="HEADER.DOWNLOAD_2" values={{
                                            a: (chunks) => (<a href="https://inkseps.com/2018/05/06/simulated-process-screen-printing-interlocking-halftones/" target="_blank" rel="noopener noreferrer">{chunks}</a>)
                                        }} />
                                    </p>
                                </Grid>
                            </Grid>
                            <Button variant="outline-secondary" size="small" onClick={() => setDownloadAnchorEl(false)}>
                                <FormattedMessage id="TEXT.CANCEL" />
                            </Button>
                            <Button className="ml-3" variant="outline-primary" size="small" onClick={() => history.push('/download')}>
                                <FormattedMessage id="HEADER.DOWNLOAD_CONFIRM" />
                            </Button>
                        </div>
                    </Popover>
                </li>
                <li className={`menu-item menu-item-rel`}>
                    <span className="menu-link" aria-describedby={"reset-confirmation"} variant="contained" onClick={(event) => setResetAnchorEl(event.currentTarget)}>
                        <span className="menu-text"><i className="fa fa-sync mr-2" /> <FormattedMessage id="HEADER.RESET_SEP_BTN" /></span>
                    </span>
                    <Popover
                        id={"reset-confirmation"}
                        open={openReset}
                        anchorEl={resetAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className="p-3 text-center">
                            <p><FormattedMessage id="HEADER.RESET_SEP" values={{s: (chunks) => (<strong>{chunks}</strong>)}} /></p>
                            <Button variant="outline-secondary" size="small" onClick={() => setResetAnchorEl(false)}>
                                <FormattedMessage id="TEXT.NO_CANCEL" />
                            </Button>
                            <Button className="ml-3" variant="outline-primary" size="small" onClick={() => {
                                setResetAnchorEl(false);
                                setPageLoader(true);
                                setTimeout(() => {
                                    flatGl.reset();
                                    setPageLoader(false);
                                }, 250);
                            }}>
                                <FormattedMessage id="HEADER.RESET_SEP_CONFIRM" />
                            </Button>
                        </div>
                    </Popover>
                </li>

                <li className={`menu-item menu-item-rel`}>
                    <span className="menu-link" aria-describedby={"new-confirmation"} variant="contained" onClick={(event) => setNewAnchorEl(event.currentTarget)}>
                        <span className="menu-text"><i className="fa fa-upload mr-2" /> <FormattedMessage id="HEADER.NEW_SEP_BTN" /></span>
                    </span>
                    <Popover
                        id={"new-confirmation"}
                        open={openNew}
                        anchorEl={newAnchorEl}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'center',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'center',
                        }}
                    >
                        <div className="p-3 text-center">
                            <p><FormattedMessage id="HEADER.NEW_SEP" values={{s: (chunks) => (<strong>{chunks}</strong>)}} /></p>
                            <Button variant="outline-secondary" size="small" onClick={() => setNewAnchorEl(false)}>
                                <FormattedMessage id="TEXT.NO_CANCEL" />
                            </Button>
                            <NavLink to="/" onClick={() => {
                                setNewAnchorEl(false);
                                flatGl.new();
                            }}>
                                <Button className="ml-3" variant="outline-primary" size="small">
                                    <FormattedMessage id="HEADER.NEW_SEP_CONFIRM" />
                                </Button>
                            </NavLink>
                        </div>
                    </Popover>
                </li>
            </>}
            {/*end::1 Level*/}
        </ul>
        {/*end::Header Nav*/}
    </div>;
}
