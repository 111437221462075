import React, { useMemo, useState, Suspense, lazy } from "react";
import objectPath from "object-path";
import cookie from "react-cookies";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { EditorPage } from "./pages/EditorPage";
import { DashboardPage } from "./pages/DashboardPage";
// LayoutContext
import {useHtmlClassService} from "../_metronic/layout/_core/MetronicLayout";
import { Aside } from "../_metronic/layout/components/aside/Aside";
import { Header } from "../_metronic/layout/components/header/Header";
import { Footer } from "../_metronic/layout/components/footer/Footer";
import { toAbsoluteUrl } from "../_metronic/_helpers/AssetsHelpers";
import { FormattedMessage } from "react-intl";

const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage({ flatGl, layers, refreshLayers, currentLayer, setCurrentLayer }) {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  const uiService = useHtmlClassService();
  // Layout settings (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
      return {
          layoutConfig: uiService.confi,
          selfLayout: objectPath.get(uiService.config, "self.layout"),
          asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
          desktopHeaderDisplay: objectPath.get(
              uiService.config,
              "header.self.fixed.desktop"
          ),
          contentCssClasses: uiService.getClasses("content", true),
          contentContainerClasses: uiService.getClasses("content_container", true),
          contentExtended: objectPath.get(uiService.config, "content.extended")
      };
  }, [uiService]);

  // page loading?
  const [pageLoader, setPageLoader] = useState(false);

  // smart merge during separate?
  flatGl.smartMerge = cookie.load("smartMerge")?.length ? parseFloat(cookie.load("smartMerge")) : 0;
  const [smartMerge, _setSmartMerge] = useState(flatGl.smartMerge);
  const setSmartMerge = (value) => {
    cookie.save("smartMerge", value, { path: "/" });
    _setSmartMerge(value);
  };

  // include gray with separation?
  flatGl.includeGray = cookie.load("includeGray") === "yes";
  const [includeGray, _setIncludeGray] = useState(flatGl.includeGray);
  const setIncludeGray = (value) => {
    flatGl.includeGray = value;
    cookie.save("includeGray", value ? "yes" : "no", { path: "/" });
    _setIncludeGray(value);
  };

  // editor view mode
  const [viewMode, _setViewMode] = useState(cookie.load("viewMode") || "leftRight");
  const setViewMode = (value) => {
    _setViewMode(value);
    cookie.save("viewMode", value, { path: "/" });
    flatGl.canvas.refreshViewportSize().fitScaleToViewport();
  };

  // shirt color
  const [shirtColor, setShirtColor] = useState([0, 0, 0, 1]);

  return (
    <>
      <div className={`page-loader flex-column align-items-center justify-content-center ${pageLoader ? "" : "d-none"}`}>
        <img src={toAbsoluteUrl("/media/logos/logo.svg")} width="200" style={{filter: "invert(19%) sepia(18%) saturate(705%) hue-rotate(200deg) brightness(91%) contrast(95%)"}} alt="InkSeps" />

        <svg className="splash-spinner" viewBox="0 0 50 50">
          <circle className="path"
            cx="25"
            cy="25"
            r="20"
            fill="none"
            strokeWidth="5"
          ></circle>
        </svg>

        <h4><FormattedMessage id="TEXT.PROCESSING" /></h4>
      </div>
      {layoutProps.asideDisplay && (<Aside
        flatGl={flatGl}
        layers={layers}
        refreshLayers={refreshLayers}
        viewMode={viewMode}
        setViewMode={setViewMode}
        smartMerge={smartMerge}
        setSmartMerge={setSmartMerge}
        includeGray={includeGray}
        setIncludeGray={setIncludeGray}
        currentLayer={currentLayer}
        setCurrentLayer={setCurrentLayer}
        shirtColor={shirtColor}
        setShirtColor={setShirtColor}
      />)}
      {/*begin::Wrapper*/}
      <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
          <Header flatGl={flatGl} currentLayer={currentLayer} setPageLoader={setPageLoader} />
          {/*begin::Content*/}
          <div
              id="kt_content"
              className={`${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid pt-0`}
          >
            <Suspense fallback={<LayoutSplashScreen />}>
              <Switch>
                <ContentRoute exact path="/" component={() => <DashboardPage flatGl={flatGl} viewMode={viewMode} setViewMode={setViewMode} currentLayer={currentLayer} setPageLoader={setPageLoader} />} />
                <ContentRoute path="/editor" component={() => <EditorPage flatGl={flatGl} viewMode={viewMode} shirtColor={shirtColor} currentLayer={currentLayer} setPageLoader={setPageLoader} />} />
                <Route path="/user-profile" component={UserProfilepage} />
                <Redirect to="error/error-v1" />
              </Switch>
            </Suspense>
          </div>
          {/*end::Content*/}
          <Footer/>
      </div>
      {/*end::Wrapper*/}
  </>
  );
}
