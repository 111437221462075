import React, { Component } from 'react'

export default class DragAndDrop extends Component {
    state = {
        dragging: false
    }
    dropRef = React.createRef()
    
    handleDrag = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }
    
    handleDragIn = (e) => {
        e.preventDefault()
        e.stopPropagation()
        if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            this.setState({dragging: true})
        }
    }
    handleDragOut = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging: false})
    }

    handleDrop = (e) => {
        e.preventDefault()
        e.stopPropagation()
        this.setState({dragging: false})
        
        if (e.dataTransfer && e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            // handle drag and drop
            this.props.handleDrop(e.dataTransfer.files)
        } else if (e.target && e.target.files) {
            // handle <input type="file" />
            this.props.handleDrop(e.target.files)
        }
    }

    componentDidMount() {
        let div = this.dropRef.current
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)
        div.addEventListener('change', this.handleDrop)
    }
    componentWillUnmount() {
        let div = this.dropRef.current
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)
        div.removeEventListener('change', this.handleDrop)
    }
    
    render() {
        const styleAbsolute = {
            position: 'absolute',
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            width: "100%",
            height: "100%",
        };
        const style = {
            ...styleAbsolute,
            border: 'dashed rgba(127, 127, 127, 0.3) 4px',
            zIndex: 50
        };
        const hoverStyle = {
            border: 'dashed grey 4px',
            backgroundColor: 'rgba(255,255,255,0.8)'
        };

        return (
            <div style={{position:'relative', width:'100%', height:'100%'}}>
                <input type="file" ref={this.dropRef} style={{...styleAbsolute, zIndex:55, opacity: 0}} />
                <div style={this.state.dragging ? {...style, ...hoverStyle} : style}>
                    {this.props.children}
                </div>
            </div>
        )
  }
}
