import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link, useParams, Redirect } from "react-router-dom";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getApiError, resetPassword } from "../_redux/authCrud";
import { FormattedMessage } from "react-intl";

const initialValues = {
  password: "",
  password_confirmation: "",
};

function ResetPassword() {
  const intl = useIntl();
  const [isReset, setIsReset] = useState(false);
  const { token, email } = useParams();

  const ResetPasswordSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, intl.formatMessage({ id: "API.MIN_6" }))
      .required(intl.formatMessage({ id: "API.PASSWORD.REQUIRED" })),
    password_confirmation: Yup.string()
      .required(intl.formatMessage({ id: "API.PASSWORD_2.REQUIRED" }))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({ id: "API.PASSWORD.MATCH" })
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ResetPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      resetPassword(decodeURIComponent(email), values.password, values.password_confirmation, token)
        .then(() => setIsReset(true))
        .catch((error) => 
          setStatus(getApiError(error, intl))
        )
        .finally(() => 
          setSubmitting(false)
        );
    },
  });

  if (!token || !email) {
    return <Redirect to="/auth/login" />
  }

  return (
    <>
      {isReset && (<div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
        <div className="alert-text font-weight-bold text-center">
          <FormattedMessage id="AUTH.RESET.SUCCESS_1" /><br /><br />
          <FormattedMessage id="AUTH.RESET.SUCCESS_2" values={{
            a: (chunks) => (<Link to="/auth/login">{chunks}</Link>)
          }} />
        </div>
      </div>)}

      {!isReset && (<div className="login-form login-forgot" style={{ display: "block" }}>
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.RESET.HEADER" />
          </h3>
          <div className="text-muted font-weight-bold">
            <FormattedMessage id="AUTH.RESET.SUBHEADER" /> {email}
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">
                {formik.status}
              </div>
            </div>
          )}{/* begin: Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/* end: Password */}

          {/* begin: Confirm Password */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password_confirmation"
              )}`}
              name="password_confirmation"
              {...formik.getFieldProps("password_confirmation")}
            />
            {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">
                  {formik.errors.password_confirmation}
                </div>
              </div>
            ) : null}
          </div>
          {/* end: Confirm Password */}

          <div className="form-group d-flex flex-wrap flex-center">
            <Link to="/auth">
              <button
                type="button"
                id="kt_login_forgot_cancel"
                className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
              >
                <FormattedMessage id="TEXT.CANCEL" />
              </button>
            </Link>
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={(!formik.isValid || formik.isSubmitting)}
            >
                <FormattedMessage id="TEXT.SUBMIT" />
              {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
        </form>
      </div>)}
    </>
  );
}

export default connect(null, auth.actions)(ResetPassword);
