import React, {useMemo} from "react";
import { FormattedMessage } from "react-intl";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function Brand() {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses("brand", true),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo()
    };
  }, [uiService]);

  return (
    <>
      {/* begin::Brand */}
      <div
          className={`brand flex-column-auto ${layoutProps.brandClasses}`}
          id="kt_brand"
      >
        {/* begin::Logo */}
        <a href="https://inkseps.com" target="_blank" rel="noopener noreferrer" className="brand-logo w-100 justify-content-center">
          <img alt="logo" src={layoutProps.headerLogo} style={{filter: "invert(100%)"}} height="40" />
          <small className="position-absolute" style={{top:"34px", right:"29%"}}>
            <FormattedMessage id="TEXT.BETA" />
          </small>
        </a>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
      </>
  );
}
