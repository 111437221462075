import React from "react";
import { injectIntl, FormattedMessage } from "react-intl";
import { connect } from "react-redux";
import * as auth from "../modules/Auth/_redux/authRedux";
import { LightTooltip } from "./LightTooltip";

export function MemberBadge({ intl, placement }) {

    return (
        <LightTooltip
            title={<FormattedMessage id="TEXT.MEMBER_ONLY" />}
            aria-label={() => intl.formatMessage({
                id: "TEXT.MEMBER_ONLY",
            })}
            placement="right"
        >
            <span className={`premium-badge ${placement}`}>
                <FormattedMessage id="TEXT.M" />
            </span>
        </LightTooltip>
    );
}

export default injectIntl(connect(null, auth.actions)(MemberBadge));
