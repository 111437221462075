/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import React from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { Layout } from "../_metronic/layout";
import BasePage from "./BasePage";
import { Logout, AuthPage } from "./modules/Auth";
import ErrorsPage from "./modules/ErrorsExamples/ErrorsPage";
import Unsupported from "./modules/Unsupported";
import TermsPage from "./modules/TermsPage";
import PrivacyPage from "./modules/PrivacyPage";
import DownloadPage from "./pages/DownloadPage";

export function Routes({ flatGl, layers, refreshLayers, currentLayer, setCurrentLayer }) {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <Switch>
      {!flatGl.isBrowserSupported ? (
        <>
          <Route path="/unsupported">
            <Unsupported />
          </Route>
          <Redirect to="/unsupported" />
        </>
      ) : (
        <Redirect exact from="/unsupported" to="/" />
      )}

      {!isAuthorized ? (
        /*Render auth page when user at `/auth` and not authorized.*/
        <Route path="/auth">
          <AuthPage />
        </Route>
      ) : (
        <Redirect from="/auth" to="/" />
      )}

      <Route path="/privacy-policy" component={PrivacyPage} />
      <Route path="/terms-and-conditions" component={TermsPage} />
      <Route path="/error" component={ErrorsPage} />
      <Route path="/logout" component={Logout} />
      <Route path="/download">
        <DownloadPage isAuthorized={isAuthorized} flatGl={flatGl} />
      </Route>
      
      <Layout flatGl={flatGl} layers={layers} refreshLayers={refreshLayers} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer}>
        <BasePage flatGl={flatGl} layers={layers} refreshLayers={refreshLayers} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} />
      </Layout>
    </Switch>
  );
}
