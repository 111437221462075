import React from "react";

export default class Canvas extends React.Component {
  ref;
  flatGl;

  constructor(props) {
    super(props);
    this.ref = React.createRef();
    this.flatGl = props.flatGl;
  }

  componentDidMount() {
    const canvas = this.flatGl.canvas;

    if (canvas) {
      canvas.setContainer(this.ref.current);
    }
  }

  componentWillUnmount() {
    if (this.flatGl.canvas) {
      this.flatGl.canvas.unsetContainer();
    }
  }

  render() {
    return <div ref={this.ref} className={this.props.className} style={this.props.style} />;
  }
}
