import React from "react";
import { shallowEqual, useSelector } from "react-redux";
import { LanguageSelectorDropdown } from "../extras/dropdowns/LanguageSelectorDropdown";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

export function Topbar() {
  const { isAuthorized } = useSelector(
    ({ auth }) => ({
      isAuthorized: auth.user != null,
    }),
    shallowEqual
  );

  return (
    <div className="topbar">
      {isAuthorized ? <QuickUserToggler /> : (
        <>
          <Link to="/auth/login" className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span className="text-dark-50 font-weight-bolder font-size-base mr-3">
                <FormattedMessage id="TEXT.LOGIN" />
              </span>
            </div>
          </Link>

          <Link to="/auth/registration" className="topbar-item">
            <div className="btn btn-icon w-auto btn-clean d-flex align-items-center btn-lg px-2">
              <span className="text-dark-50 font-weight-bolder font-size-base mr-3">
                <FormattedMessage id="TEXT.REGISTER" />
              </span>
            </div>
          </Link>
        </>
      )}

      <LanguageSelectorDropdown />
    </div>
  );
}
