
import React, { useState, useRef } from "react";
import { Curve } from "./Curve";
import { CurveNode } from "./CurveNode";

export function CurvesEditor({lightMode, curveNodes, setCurveNodes, linear, width = 216, height = 216}) {
    let ref = useRef(null);

    // setup state
    const [currentNode, setCurrentNode] = useState(null);
    const [dragging, setDragging] = useState(false);

    /**
     * 
     * @param {*} e 
     * @returns 
     */
    const onMouseDown = (e) => {
        if (!ref.current) {
            return;
        }

        const coords = getCoords(e),
            point = [coords[0], coords[1]],
            w = ref.current.clientWidth,
            h = ref.current.clientHeight;
        // check to see if we clicked existing point
        let index = 0;
        for (let i = 0, l = curveNodes.length; i < l; i++) {
            var p = curveNodes[i],
                x = (p[0] - point[0]) * w,
                y = (p[1] - point[1]) * h;
            if (x * x + y * y < 5 * 5) {
                setCurrentNode(i);
                break;
            }
            index++;
        }

        // add new point if we need to
        if (index === curveNodes.length) {
            curveNodes.push(point);
            curveNodes.sort((a, b) => a[0] < b[0] ? -1 : 1);
            setCurrentNode(curveNodes.indexOf(point));
            setCurveNodes([...curveNodes]);
        }

        setDragging(true);
    };

    /**
     * 
     * @param {*} e 
     */
    const onMouseUp = (e) => {
        updateCurrentNodeCoords(e);
        setCurrentNode(null);
        setDragging(false);
    };

    /**
     * 
     * @param {*} e 
     */
    const onMouseMove = (e) => {
        updateCurrentNodeCoords(e);
    }

    /**
     * 
     * @param {*} e 
     * @returns 
     */
    const updateCurrentNodeCoords = (e) => {
        if (!ref.current) {
            return;
        }

        if (dragging && currentNode !== null) {
            const coords = getCoords(e);
            const node = curveNodes[currentNode];
            curveNodes[currentNode][0] = coords[0];
            curveNodes[currentNode][1] = coords[1];
            curveNodes.sort((a, b) => a[0] < b[0] ? -1 : 1);

            setCurrentNode(curveNodes.indexOf(node));
            setCurveNodes([...curveNodes]);
        }
    }

    /**
     * get mouse x/y relative to container position
     */
    const getCoords = (e) => {
        var x = e.pageX,
            y = e.pageY;
        x -= ref.current.getBoundingClientRect().left;
        y -= ref.current.getBoundingClientRect().top;

        return [
            x / ref.current.clientWidth,
            1 - (y / ref.current.clientHeight)
        ]
    };

    // construct grid path
    const gridPath = ["M 0 0"];
    const divisions = 0.1;
    for (let x = 1.0; x >= 0; x -= divisions) {
        const offsetX = x * width;
        const offsetY = x * height;
        // vertical
        gridPath.push(`M ${offsetX} 0 L ${offsetX} ${height}`)

        // horizontal
        gridPath.push(`M 0 ${offsetY} L ${width} ${offsetY}`);
    }

    // create circle at each node
    const nodeList = curveNodes.map((node, index) => {
        return <CurveNode fill={lightMode ? "white" : "black"} key={index} x={node[0] * 216} y={(1.0 - node[1]) * 216} />
    });

    return (<div ref={ref} onMouseDown={onMouseDown} onMouseMove={onMouseMove} onMouseUp={onMouseUp} style={{width:"216px", height:"216px"}}>
    <svg width="216" height="216" xmlns="http://www.w3.org/2000/svg">
        <path d={gridPath.join(" ")} stroke={lightMode ? "#606060" : "#999999"} fill="transparent" />
        <Curve stroke={lightMode ? "white" : "black"} nodes={curveNodes} linear={linear} width={width} height={height} />
        {nodeList}
    </svg>
</div>)
}
