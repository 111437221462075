import React, {useMemo} from "react";
import {Link} from "react-router-dom";
import objectPath from "object-path";
import {Brand} from "../brand/Brand";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../_core/MetronicLayout";

export function Aside({
  flatGl,
  layers,
  refreshLayers,
  viewMode,
  setViewMode,
  smartMerge,
  setSmartMerge,
  includeGray,
  setIncludeGray,
  currentLayer,
  setCurrentLayer,
  shirtColor,
  setShirtColor,
}) {
  const uiService = useHtmlClassService();

  const layoutProps = useMemo(() => {
    return {
      disableScroll:
          objectPath.get(uiService.config, "aside.menu.dropdown") === "true" ||
          false,
      asideClassesFromConfig: uiService.getClasses("aside", true),
      disableAsideSelfDisplay:
          objectPath.get(uiService.config, "aside.self.display") === false,
      headerLogo: uiService.getLogo(),
      layoutConfig: uiService.config,
      asideMenuAttr: uiService.getAttributes("aside_menu"),
      ulClasses: uiService.getClasses("aside_menu_nav", true),
      asideMenuClassesFromConfig: uiService.getClasses("aside_menu", true),
    };
  }, [uiService]);

  return (
      <>
        {/* begin::Aside */}
        <div id="kt_aside"
             className={`aside aside-left  ${layoutProps.asideClassesFromConfig} d-flex flex-column flex-row-auto`}>
          <Brand/>

          {/* begin::Aside Menu */}
          <div id="kt_aside_menu_wrapper" className="aside-menu-wrapper flex-column-fluid">
            {layoutProps.disableAsideSelfDisplay && (
              <>
                {/* begin::Header Logo */}
                <div className="header-logo">
                  <Link to="">
                    <img alt="logo" src={layoutProps.headerLogo}/>
                  </Link>
                </div>
                {/* end::Header Logo */}
              </>
            )}
            
            {/* begin::Menu Container */}
            <div
              id="kt_aside_menu"
              data-menu-vertical="1"
              className={`aside-menu my-4 ${layoutProps.asideMenuClassesFromConfig}`}
              {...layoutProps.asideMenuAttr}
            >
              <AsideMenuList
                disableScroll={layoutProps.disableScroll}
                flatGl={flatGl}
                layers={layers}
                refreshLayers={refreshLayers}
                viewMode={viewMode}
                setViewMode={setViewMode}
                smartMerge={smartMerge}
                setSmartMerge={setSmartMerge}
                includeGray={includeGray}
                setIncludeGray={setIncludeGray}
                currentLayer={currentLayer}
                setCurrentLayer={setCurrentLayer}
                shirtColor={shirtColor}
                setShirtColor={setShirtColor}
              />
            </div>
            {/* end::Menu Container */}
          </div>
          {/* end::Aside Menu */}
        </div>
        {/* end::Aside */}
      </>
  );
}
