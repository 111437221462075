import React from "react";
import { LayerThumbnail } from "./LayerThumbnail";
import { LightTooltip } from "../LightTooltip";
import { LayerCWB } from "./LayerCWB";
import { Grid } from '@material-ui/core';
import { LayerMerge } from "./LayerMerge";
import { LayerVisibilityToggle } from "./LayerVisibilityToggle";
import { FormattedMessage } from "react-intl";

const disallowMergeInto = ["Original", "WhiteBase", "White"];
const layerThumbWrapper = {width:"44px", height:"44px"};

export function Layer({flatGl, layer, layers, locked, getCompactLayerList, currentLayer, setCurrentLayer, openDialog, openLayerDialog}) {
    return (
        <li
            className={`menu-item menu-item-submenu mb-2 ${layer === currentLayer ? "menu-item-open" : ""}`}
            onClick={() => setCurrentLayer(layer)}
            aria-haspopup="true"
            data-menu-toggle="hover"
        >
            <div className="menu-link py-0 px-2">
                <div style={layerThumbWrapper} className="menu-toggle">
                    {(!openDialog || currentLayer !== layer) && <LayerThumbnail flatGl={flatGl} layer={layer} style={{width: "44px", height: "44px"}}></LayerThumbnail>}
                </div>
                <span className="ml-2 menu-text menu-toggle">{layer.name}</span>
                
                {(!locked || layer.name === "Original") && <div className="btn pr-0">
                    <LayerVisibilityToggle lightMode={true} layer={layer} />
                </div>}
                {!locked && <div className="btn pr-0" onClick={() => openLayerDialog(layer)}>
                    <LightTooltip title={<FormattedMessage id="EDITOR.EDIT_LAYER" />} aria-label="Edit Layer" placement="bottom">
                        <i className="fa fa-external-link-alt"></i>
                    </LightTooltip>
                </div>}
            </div>
            
            {!locked && <div className="menu-submenu mb-1 border-bottom border-themed">
                <ul className="menu-subnav" style={{backgroundColor:'#1b1b28'}}>
                    <li className="menu-item menu-item-parent" aria-haspopup="true">
                        <span className="menu-link">
                            <span className="menu-text">{layer.name}</span>
                        </span>
                    </li>
                    {/*begin::2 Level*/}
                    <li className="px-3 pb-1 pt-2">
                        <LayerCWB layer={layer} layers={layers} />
            
                        <Grid container spacing={1} className="border-top border-themed mt-1 pt-2 pb-1">
                            <Grid item xs onClick={() => layer.remove()}>
                                <LightTooltip title={<FormattedMessage id="TEXT.REMOVE" />} aria-label="Remove" placement="bottom" className="w-100 text-center cursor-pointer">
                                    <i className="fa fa-trash"></i>
                                </LightTooltip>
                            </Grid>
                            {disallowMergeInto.indexOf(layer.name) === -1 && <LayerMerge lightMode={true} layer={layer} setCurrentLayer={setCurrentLayer} getCompactLayerList={getCompactLayerList} disallowMergeInto={disallowMergeInto} />}
                        </Grid>
                    </li>
                    {/*end::2 Level*/}
                </ul>
            </div>}
        </li>
    );
}
