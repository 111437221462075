import { Canvas, Surface, OverlayFilter, SeparateCanvasEffect, ToggleGraySeparationEffect } from "@flat-gl/core";
import { saveAs } from "file-saver";
import JSZip from "jszip";

const userAgent = navigator.userAgent.toLowerCase();

class FlatGL {
  isBrowserSupported =
    userAgent.indexOf("firefox") > -1
    || userAgent.indexOf("chrome") > -1
    || userAgent.indexOf("edg") > -1
    || userAgent.indexOf("safari") > -1;
  canvas = new Canvas({ scaleAndPan: true });
  previewSurfaceA = new Surface({ scaleAndPan: true });

  hues = 6;
  includeGray = false;
  smartMerge = 0;
  file = null;

  constructor() {
    this.previewSurfaceA.mirrorSurface(this.canvas);
  }

  open(file) {
    this.file = file;

    return this.canvas.open(file)
      .then(() => {
        this.previewSurfaceA.resize(this.canvas.width, this.canvas.height);
        this.applyEffect();
      });
  };

  applyEffect() {
    this.canvas.applyEffect(SeparateCanvasEffect, {
      source: this.canvas.getLayer(),
      colors: this.hues,
      smartMerge: this.smartMerge > 0,
      smartMergeThreshold: this.smartMerge,
      includeGray: !!this.includeGray,
    });
    
    this.canvas.layers.forEach((layer) => {
      // save original overlay color so that it can be used later by reset button
      const overlayFilter = layer.getFilterByType(OverlayFilter)[0];
      if (overlayFilter) {
        layer.originalOverlayColor = JSON.parse(JSON.stringify(overlayFilter.rgba));
      }
    });
  }

  updateGray() {
    this.canvas.applyEffect(ToggleGraySeparationEffect, {
      enabled: this.includeGray
    });
    if (this.includeGray) {
      const grayLayer = this.canvas.findLayer("Gray");
      const overlayFilter = grayLayer.getFilterByType(OverlayFilter)[0];
      grayLayer.originalOverlayColor = [...overlayFilter.rgba];
    }
  }

  reset() {
    const removed = this.canvas.layers.splice(0, this.canvas.layers.length - 1);
    // since we are splicing layers array directly we need to handle removing sprites from canvas
    removed.forEach((layer) => layer.sprite.destroy());
    this.applyEffect();
  }

  new() {
    this.previewSurfaceA.mirroredSurfaces = [];

    // create new canvas and remove old
    const canvas = this.canvas;
    this.canvas = new Canvas({ scaleAndPan: true });
    canvas.destroy();

    this.previewSurfaceA.mirrorSurface(this.canvas);

    this.file = null;
  }

  save() {
    const zip = new JSZip();
    const canvas = this.canvas;
    
    // place each layer within zip
    canvas.layers.forEach((layer) => {
      let fileName = layer.name;

      // do we have an overlay?
      const overlayFilter = layer.getFilterByType(OverlayFilter)[0];
      if (overlayFilter) {
        overlayFilter.enabled = false;
        layer.render();
        fileName += ` [${Math.round(overlayFilter.rgba[0] * 255)},${Math.round(overlayFilter.rgba[1] * 255)},${Math.round(overlayFilter.rgba[2] * 255)}]`;
      }

      // add layer to zip archive
      zip.file(
        `${fileName}.png`,
        layer.export({xPPU: 11811, yPPU: 11811, return: new Blob()})
      );

      // re-enable overlayFilter
      if (overlayFilter) {
        overlayFilter.enabled = true;
      }
    });

    // generate zip and force download
    return zip.generateAsync({type:"blob"})
      // prompt to save download
      .then((content) => saveAs(content, this.file.name + "-separation.zip"))
      // ugly fix for canvas scale auto-setting to 1% @TODO - resolve properly
      .then(() => setTimeout(() => canvas.fitScaleToViewport(), 100));
  }

  readPixel(x, y) {
    console.log(this.canvas.readPixel(x, y));
  }
}

export default FlatGL;
