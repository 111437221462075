/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useState } from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { AuthInit } from "./modules/Auth";
import { Routes } from "../app/Routes";
import { I18nProvider } from "../_metronic/i18n";
import { LayoutSplashScreen, MaterialThemeProvider } from "../_metronic/layout";
import { OverlayFilter } from "@flat-gl/core";

export default function App({ flatGl, store, persistor, basename }) {
  // current layer
  const [currentLayer, _setCurrentLayer] = useState(null);
  const setCurrentLayer = (layer) => {
    if (layer === currentLayer) {
      return;
    }
    
    if (currentLayer) {
      // re-enable OverlayFilter
      currentLayer.filters.forEach((filter) => {
        if (filter instanceof OverlayFilter) {
            filter.enabled = true;
        }
      });
    }

    _setCurrentLayer(layer);
  };

  // layer list
  const [layers, setLayers] = useState([]);
  const refreshLayers = () => {
    const layers = [];

    flatGl.canvas.layers.forEach((layer) => layers.push(layer));
    layers.reverse();

    // set current layer if one is not set
    if (currentLayer === null) {
      setCurrentLayer(layers[0]);
    }
    if (layers.length === 0) {
      setCurrentLayer(null);
    }

    setLayers(layers);
  }
  //
  flatGl.canvas.on(["canvas.effect.applied", "canvas.cleared", "canvas.destroyed", "layer.removed"], (canvas) => refreshLayers());

  return (
    /* Provide Redux store */
    <Provider store={store}>
      {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
      <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
        {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
        <React.Suspense fallback={<LayoutSplashScreen />}>
          {/* Override `basename` (e.g: `homepage` in `package.json`) */}
          <BrowserRouter basename={basename}>
            {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
            <MaterialThemeProvider>
              {/* Provide `react-intl` context synchronized with Redux state.  */}
              <I18nProvider>
                {/* Render routes with provided `Layout`. */}
                <AuthInit>
                  <Routes flatGl={flatGl} layers={layers} refreshLayers={refreshLayers} currentLayer={currentLayer} setCurrentLayer={setCurrentLayer} />
                </AuthInit>
              </I18nProvider>
            </MaterialThemeProvider>
          </BrowserRouter>
        </React.Suspense>
      </PersistGate>
    </Provider>
  );
}
