import React from 'react';
import reactCSS from 'reactcss';

export var SaturationPointer = function SaturationPointer() {
  var styles = reactCSS({
    'default': {
        picker: {
            width: '12px',
            height: '12px',
            border: '2px solid #FFF',
            borderRadius: '6px',
            boxShadow: '0 0 2px 1px rgba(0, 0, 0, 0.3)',
            transform: 'translate(-6px, -6px)'
        }
    }
  });

  return React.createElement('div', { style: styles.picker });
};

export default SaturationPointer;