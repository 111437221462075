import React, { useState, useEffect } from "react";
import { Grid, Slider } from "@material-ui/core";
import { LightTooltip } from "../LightTooltip";
import { SaturationFilter } from "@flat-gl/core";
import { FormattedMessage } from "react-intl";

export function LayerSaturation({lightMode, layer}) {
    const [saturation, setSaturation] = useState(0);
    
    const setLayerSaturation = (event, value) => {
        layer.setFilter(SaturationFilter, [[value / 100]]);
        setSaturation(value);
    };

    useEffect(() => {
        if (!layer) {
            return;
        }

        const filter = layer.getFilterByType(SaturationFilter)[0];
        if (filter) {
            setSaturation(filter.saturation * 100);
        } else {
            setSaturation(0);
        }
    }, [layer]);

    // no layer
    if (!layer) {
        return <></>;
    }

    return (
<Grid container spacing={2} alignItems="center" className="mt-2 py-2 border-top border-themed">
    <Grid item>
        <LightTooltip title={<FormattedMessage id="TEXT.SATURATION" />} aria-label="Saturation" placement="right">
            <i className="fa fa-adjust mb-1"></i>
        </LightTooltip>
    </Grid>
    <Grid item xs className="pb-0">
        <Slider
            min={-100}
            max={100}
            valueLabelDisplay="auto"
            value={saturation}
            onChange={setLayerSaturation}
        />
    </Grid>
    <Grid item>
        <LightTooltip title={<FormattedMessage id="TEXT.RESET" />} aria-label="Reset" placement="right">
            <i onClick={() => setLayerSaturation(null, 0)} className={`fa fa-undo cursor-pointer ${lightMode ? "text-light" : "text-dark"}`}></i>
        </LightTooltip>
    </Grid>
</Grid>)
}