import axios from "axios";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/login`;
export const REGISTER_URL = `${process.env.REACT_APP_API_URL}/register`;
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/reset/email`;
export const RESET_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/reset/password`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/me`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, name, password, password_confirmation, newsletter) {
  return axios.post(REGISTER_URL, { email, name, password, password_confirmation, newsletter });
}

export function requestPassword(email, base_url) {
  return axios.post(REQUEST_PASSWORD_URL, { email, base_url });
}

export function resetPassword(email, password, password_confirmation, token) {
  return axios.post(RESET_PASSWORD_URL, { email, password, password_confirmation, token });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

export function getApiError(error, intl) {
  if (error.response && error.response.data?.errors) {
    for (let key in error.response.data.errors) {
      if (error.response.data.errors.hasOwnProperty(key)) {
        return intl.formatMessage({ id: error.response.data.errors[key][0] })
      }
    }
  }

  return intl.formatMessage({ id: "API.SOMETHING_WRONG" })
}