import React from "react";
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid, Paper } from '@material-ui/core';
import { LayerThumbnail } from "./LayerThumbnail";
import { LayerCWB } from "./LayerCWB";
import { LayerEditor } from "./LayerEditor";
import Draggable from "react-draggable";

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

export function LayerDialog({
  flatGl,
  layer,
  setCurrentLayer,
  layers,
  refreshLayers,
  getCompactLayerList,
  openDialog,
  onClose,
}) {

  return (
    <Dialog
        maxWidth="md"
        fullWidth={true}
        open={openDialog}
        onClose={() => {}}
        PaperComponent={PaperComponent}
      >
        <DialogTitle style={{ cursor: 'move' }}>
          <Grid container direction="row" alignItems="center" alignContent="center" >
            <Grid item>{layer?.name}</Grid>
            <Grid item xs={4}><div className="btn btn-dark w-100 ml-3 py-1"><LayerCWB layer={layer} layers={layers} /></div></Grid>
            <Grid item xs className="text-right"><i onClick={onClose} className="fa fa-times text-dark cursor-pointer"></i></Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2} direction="row">
            <Grid item xs>
              <div className="border checkered-background">
                {openDialog && <LayerThumbnail flatGl={flatGl} layer={layer} style={{width: "100%", height: "620px"}} useSurface={true} mirror={false} />}
              </div>
            </Grid>
            <Grid item>
              <LayerEditor toolsOnly={true} currentLayer={layer} setCurrentLayer={setCurrentLayer} getCompactLayerList={getCompactLayerList} refreshLayers={refreshLayers} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color="primary">
            Close
          </Button>
        </DialogActions>
    </Dialog>
  );
}
