import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import { useIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getApiError, requestPassword } from "../_redux/authCrud";
import { FormattedMessage } from "react-intl";

const initialValues = {
  email: "",
};

function ForgotPassword() {
  const intl = useIntl();
  const [isRequested, setIsRequested] = useState(false);

  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .min(1)
      .email(intl.formatMessage({ id: "API.EMAIL.FORMAT" }))
      .required(intl.formatMessage({ id: "API.EMAIL.REQUIRED" })),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      // construct base_path with location and PUBLIC_URL (with trailing slash removed)
      requestPassword(values.email, window.location.origin + process.env.PUBLIC_URL.replace(/\/$/, "") + "/auth/reset-password")
        .then(() => setIsRequested(true))
        .catch((error) => 
          setStatus(getApiError(error, intl))
        )
        .finally(() => 
          setSubmitting(false)
        );
    },
  });

  return (
    <>
      {isRequested && (<div className="mb-10 alert alert-custom alert-light-success alert-dismissible">
        <div className="alert-text font-weight-bold text-center">
          <FormattedMessage id="AUTH.FORGOT.SUCCESS_1" /><br /><br />
          <FormattedMessage id="AUTH.FORGOT.SUCCESS_2" values={{
            a: (chunks) => (<Link to="/auth/login">{chunks}</Link>)
          }} />
        </div>
      </div>)}

      {!isRequested && (
        <div className="login-form login-forgot" style={{ display: "block" }}>
          <div className="text-center mb-10 mb-lg-20">
            <h3 className="font-size-h1">
              <FormattedMessage id="AUTH.FORGOT.HEADER" />
            </h3>
            <div className="text-muted font-weight-bold">
              <FormattedMessage id="AUTH.FORGOT.SUBHEADER" />
            </div>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
          >
            {formik.status && (
              <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                <div className="alert-text font-weight-bold">
                  {formik.status}
                </div>
              </div>
            )}
            <div className="form-group fv-plugins-icon-container">
              <input
                placeholder={intl.formatMessage({ id: "TEXT.EMAIL" })}
                type="email"
                className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                  "email"
                )}`}
                name="email"
                {...formik.getFieldProps("email")}
              />
              {formik.touched.email && formik.errors.email ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">{formik.errors.email}</div>
                </div>
              ) : null}
            </div>
            <div className="form-group d-flex flex-wrap flex-center">
              <Link to="/auth">
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
                >
                  <FormattedMessage id="TEXT.CANCEL" />
                </button>
              </Link>
              <button
                id="kt_login_forgot_submit"
                type="submit"
                className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
                disabled={(!formik.isValid || formik.isSubmitting)}
              >
                <FormattedMessage id="TEXT.SUBMIT" />
                {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default connect(null, auth.actions)(ForgotPassword);
