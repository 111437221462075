import React from "react";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";
import { connect } from "react-redux";
import * as auth from "../modules/Auth/_redux/authRedux";
import { OverlayFilter } from "@flat-gl/core";
import { FormattedMessage,useIntl } from "react-intl";

function DownloadPage({isAuthorized, flatGl}) {
    const history = useHistory();
    const intl = useIntl();

    // do we have a file to download?
    if (!flatGl.file) {
        return <Redirect to="/"></Redirect>
    }

    // logged in?
    if (!isAuthorized) {
        return <Redirect to="/auth/login"></Redirect>
    }

    // get current layer color info
    const colors = [];
    flatGl.canvas.layers.forEach((layer) => {
        const filter = layer.getFilterByType(OverlayFilter)[0];
        if (filter) {
            colors.push(filter.rgba);
        }
    })

    // attempt separation
    axios.post(`${process.env.REACT_APP_API_URL}/separate`, {
            width: flatGl.canvas.width,
            height: flatGl.canvas.height,
            bytes: flatGl.file.size,
            mimetype: flatGl.file.type,
            histogram: JSON.stringify(flatGl.canvas.findLayer('Original').getHistogram()),
            colors: JSON.stringify(colors),
            app_name: `${process.env.REACT_APP_NAME}-${process.env.NODE_ENV}`,
            app_version: `${process.env.REACT_APP_VERSION}`,
            i18n: intl.locale,
        })
        .then((response) => {
            return flatGl.save();
        })
        .catch((e) => {
            alert(intl.formatMessage({
                id: "DOWNLOAD.ERROR",
            }));
        })
        .finally(() => history.push("/editor"));

    return (
        <div className={`page-loader flex-column align-items-center justify-content-center`}>
            <img src={toAbsoluteUrl("/media/logos/logo.svg")} width="200" style={{filter: "invert(19%) sepia(18%) saturate(705%) hue-rotate(200deg) brightness(91%) contrast(95%)"}} alt="InkSeps" />

            <svg className="splash-spinner" viewBox="0 0 50 50">
                <circle className="path"
                cx="25"
                cy="25"
                r="20"
                fill="none"
                strokeWidth="5"
                ></circle>
            </svg>

            <h4><FormattedMessage id="DOWNLOAD.PACKING" /></h4>
        </div>
    );
}

export default connect(null, auth.actions)(DownloadPage);
