import React from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { Link, useHistory } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import { getApiError, register } from "../_redux/authCrud";

const initialValues = {
  name: "",
  email: "",
  password: "",
  password_confirmation: "",
  acceptTerms: false,
  newsletter: true,
};

function Registration(props) {
  const history = useHistory();
  const intl = useIntl();

  const RegistrationSchema = Yup.object().shape({
    name: Yup.string()
      .required(intl.formatMessage({ id: "API.NAME.REQUIRED" })),
    email: Yup.string()
      .email(intl.formatMessage({ id: "API.EMAIL.FORMAT" }))
      .required(intl.formatMessage({ id: "API.EMAIL.REQUIRED" })),
    password: Yup.string()
      .min(6, intl.formatMessage({ id: "API.MIN_6" }))
      .required(intl.formatMessage({ id: "API.PASSWORD.REQUIRED" })),
    password_confirmation: Yup.string()
      .required(intl.formatMessage({ id: "API.PASSWORD_2.REQUIRED" }))
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          intl.formatMessage({ id: "API.PASSWORD.MATCH" })
        ),
      }),
    acceptTerms: Yup.bool()
      .required(intl.formatMessage({ id: "API.REGISTER.TERMS" })),
    newsletter: Yup.bool().optional(),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }
    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);

      register(values.email, values.name, values.password, values.password_confirmation, values.newsletter)
        .then(({ data: { token } }) => {
          props.register(token);
        })
        .catch((error) => 
          setStatus(getApiError(error, intl))
        )
        .finally(() => 
          setSubmitting(false)
        );
    },
  });

  return (
    <div className="login-form login-signin">
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.HEADER" />
        </h3>
        <p className="text-muted font-weight-bold">
          <FormattedMessage id="AUTH.REGISTER.SUBHEADER" />
        </p>
      </div>

      <form
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* begin: name */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.FULL_NAME" })}
            type="text"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "name"
            )}`}
            name="name"
            {...formik.getFieldProps("name")}
          />
          {formik.touched.name && formik.errors.name ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.name}</div>
            </div>
          ) : null}
        </div>
        {/* end: name */}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "TEXT.EMAIL" })}
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.PASSWORD" })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder={intl.formatMessage({ id: "AUTH.INPUT.CONFIRM_PASSWORD" })}
            type="password"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password_confirmation"
            )}`}
            name="password_confirmation"
            {...formik.getFieldProps("password_confirmation")}
          />
          {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.password_confirmation}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="acceptTerms"
              className="m-1"
              {...formik.getFieldProps("acceptTerms")}
            />
            <span />
            <Link
              to="/terms-and-conditions"
              target="_blank"
              className="ml-3"
              rel="noopener noreferrer"
            >
              <FormattedMessage id="AUTH.REGISTER.I_AGREE" />
            </Link>
          </label>
          {formik.touched.acceptTerms && formik.errors.acceptTerms ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.acceptTerms}</div>
            </div>
          ) : null}
        </div>
        {/* end: Terms and Conditions */}

        {/* begin: Newsletter */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="newsletter"
              className="m-1"
              defaultChecked
              {...formik.getFieldProps("newsletter")}
            />
            <span />
            <div className="d-inline-block ml-3">
              <FormattedMessage id="AUTH.REGISTER.NEWSLETTER" />
            </div>
          </label>
        </div>
        {/* end: Newsletter */}

        <div className="form-group d-flex flex-wrap flex-center">
          <button
            type="button"
            className="btn btn-light-primary font-weight-bold px-9 py-4 my-3 mx-4"
            onClick={() => history.goBack()}
          >
            <FormattedMessage id="TEXT.CANCEL" />
          </button>

          <button
            type="submit"
            disabled={
              formik.isSubmitting ||
              !formik.isValid ||
              !formik.values.acceptTerms
            }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span><FormattedMessage id="TEXT.REGISTER" /></span>
            {formik.isSubmitting && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default connect(null, auth.actions)(Registration);
