import React from "react";

export default function Unsupported(props) {
  //const { intl } = props;

  return (
    <div className="d-flex flex-fill flex-column align-items-center justify-content-center">
        <h3 className="font-size-h1 mb-8">
            Browser Unsupported
        </h3>

        <h5 className="font-weight-bold text-center">
            <i className="fab fa-firefox pr-1" />Firefox is currently the only supported browser for this application.
            <br /><br />
            Please <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer">download Firefox here</a> and then open this page in the browser.
            <br /><br />
            <a href="https://www.mozilla.org/en-US/firefox/new/" target="_blank" rel="noopener noreferrer" className="btn btn-outline-primary mb-2 mb-xl-0"><i className="fab fa-firefox pr-1" /> Download Firefox</a>
        </h5>
    </div>
  );
}
