import React from "react";
import { Grid } from '@material-ui/core';
import { LightTooltip } from "../LightTooltip";
import { LayerCWB } from "./LayerCWB";
import { LayerColor } from "./LayerColor";
import { LayerSaturation } from "./LayerSaturation";
import { LayerCurves } from "./LayerCurves";
import { LayerLevels } from "./LayerLevels";
import { LayerMerge } from "./LayerMerge";
import { LayerVisibilityToggle } from "./LayerVisibilityToggle";
import { FormattedMessage } from "react-intl";

const disallowRemove = ["Original", "WhiteBase", "White"];
const disallowMergeInto = ["Original", "WhiteBase", "White"];

export function LayerEditor({
    lightMode,
    toolsOnly,
    currentLayer,
    setCurrentLayer,
    getCompactLayerList,
    layers,
    refreshLayers,
}) {
    const allowRemove = disallowRemove.indexOf(currentLayer.name) === -1;

    // no layer
    if (!currentLayer) {
        return <></>;
    }
    
    return (
        <>
            {toolsOnly === false && <>
                <h4 className={`${lightMode ? "text-light" : "text-dark"} text-center`}>
                    {currentLayer.name}
                </h4>
                <LayerCWB layer={currentLayer} layers={layers} />
            </>}
            
            <Grid container spacing={1} className="border-top border-themed mt-1 pt-2 pb-1">
                {allowRemove && <Grid item xs onClick={() => currentLayer.remove()} >
                    <LightTooltip title={<FormattedMessage id="TEXT.REMOVE" />} aria-label="Remove" placement="bottom" className="w-100 text-center cursor-pointer">
                        <i className={`${lightMode ? "text-light" : "text-dark"} fa fa-trash`}></i>
                    </LightTooltip>
                </Grid>}
                {disallowMergeInto.indexOf(currentLayer.name) === -1 && <LayerMerge lightMode={lightMode} layer={currentLayer} setCurrentLayer={setCurrentLayer} getCompactLayerList={getCompactLayerList} disallowMergeInto={disallowMergeInto} />}
                {disallowMergeInto.indexOf(currentLayer.name) === -1 && <LayerVisibilityToggle lightMode={lightMode} layer={currentLayer} />}
            </Grid>
            
            <LayerColor lightMode={lightMode} layer={currentLayer} refreshLayers={refreshLayers} />
            {currentLayer.name !== "Original" && <>
                <LayerSaturation lightMode={lightMode} layer={currentLayer} />
                <LayerLevels lightMode={lightMode} layer={currentLayer} />
                <LayerCurves lightMode={lightMode} layer={currentLayer} />
            </>}
        </>
    );
}
