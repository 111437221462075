
import React from "react";
import { SplineInterpolator, LinearSplineInterpolator } from "@flat-gl/core";

export function Curve({width, height, nodes, linear = true, stroke = "black"}) {
    // interpolate between given nodes
    const interpolator = linear ? new LinearSplineInterpolator(nodes) : new SplineInterpolator(nodes);
    const linePoints = [];
    const lineResolution = 255;
    for (let i = 0; i <= lineResolution; i++) {
        // clamp vals 0-1
        const v = Math.min(1, Math.max(0, interpolator.interpolate(i / lineResolution)));
        linePoints.push(v);
    }

    // construct path
    const path = ["M 0 " + height];
    for (let i = 0; i <= lineResolution; i++) {
        path.push("L " + (i / lineResolution * width) + " " + ((1 - linePoints[i]) * height));
    }

    return (<>
        <path d={path.join(" ")} stroke={stroke} fill="transparent" />
    </>)
}
