import React from "react";
import { FormattedMessage } from "react-intl";

export function ComingSoon({ children, dark }) {

    return (<div className="opacity-50 position-relative" style={{pointerEvents:"none"}}>
        <h3 className={`coming-soon ${!!dark ? 'text-dark' : 'text-light'}`}>
            <FormattedMessage id="TEXT.COMING_SOON" />
        </h3>
        {children}
    </div>);
}
